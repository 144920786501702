// import React from "react";
// import { testimonal } from "../../../dummydata";
// import Title from "../../common/title/Title";

// const Testimonal = () => {
//   return (
//     <>
//       <section className="py-16 bg-gray-50">
//         <div className="container mx-auto text-Red">
//           <Title subtitle="TESTIMONIAL" title="Our Successful Students" />

//           <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
//             {testimonal.map((val, index) => (
//               <div key={index} className="bg-Blue p-6 rounded-lg shadow-lg">
//                 <div className="flex items-center mb-4">
//                   <div className="relative w-16 h-28">
//                     <img
//                       src={val.cover}
//                       alt={val.name}
//                       className="w-full h-full rounded-full object-cover"
//                     />
                   
//                   </div>
//                   <div className="ml-4">
//                     <h2 className="text-xl font-semibold text-white">{val.name}</h2>
//                     <span className="text-white font-semibold">{val.post}</span>
//                   </div>
//                 </div>
//                 <p className="text-white">{val.desc}</p>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Testimonal;

import React from 'react';
import { Link } from 'react-router-dom'; // To link to new pages
// import './BlogSection.css';
// Example Blog data
const Testimonals = [
  {
    id: 1,
    title: 'Syed Muhammad Asad',
    post:"From Sports Enthusiast to Social Media Specialist",
    image: '/sa.jpg', // Image path
    description: `Hi, I’m Asad, a social media marketing manager at Conductivity and a passionate sports enthusiast. My journey has been shaped by two driving.….`,
   },
  {
    id: 2,
    title: ' Zeeshan’s Story',
    post:'Journey of Mentorship and Growth',
    image: '/ze.jpg', // Image path
    description: `Hi, I’m Zeeshan Hirani. I am not directly employed at Conductivity, but I had the privilege of working closely with Mirza Faizan Baig during my....`,
   },
  {
    id: 3,
    title: 'Syed Saqib Hassain',
    post:"Establishing a Thriving Recruitment Department at Conductivity Career & HR Solutions",
    image: '/saqib.jpg', // Image path
    description: `In September 2021, I joined Conductivity Career & HR Solutions with the vision of creating something....`,
   },
];

const  Testimonal = () => {
  return (
    <div className="blog-section bg-white py-8">
      <h2 className='text-Red font-semibold text-center py-4 text-4xl'>Our Success story</h2>
      {/* <h2 className='text-Red font-semibold text-center py-2 text-base'>Our Successful Employer</h2> */}
      <div className="Testimonal-boxes Testimonal-boxes flex flex-wrap justify-center gap-8 px-12">
        {Testimonals.map((Testimonal) => (
          <div className="Testimonal-box justify-between px-7 bg-white shadow-lg rounded-lg overflow-hidden lg:w-80 lg:h-96 h-80 w-72 transition-transform transform hover:scale-105" key={Testimonal.id}>
            
            <img src={Testimonal.image} alt={Testimonal.title} className="Testimonal-image justify-between w-64 h-36 px-2 py-3 object-cover lg:w-full lg:h-48" />
            <h3 className="text-lg font-bold text-center text-Blue">{Testimonal.title}</h3>
            <h3 className="lg:text-sm font-bold text-center text-Blue text-xs">{Testimonal.post}</h3>
            <p className="lg:text-sm text-xs text-Blue mt-2">{Testimonal.description}<label><Link to={`/Testimonal/${Testimonal.id}`}   className="read-more-btn text-Red hover:text-Blue">Read More</Link>
            </label> </p>
             
          </div>
        ))}
      </div>
    </div>
  );
};

export default  Testimonal;
