
// import React from "react";
// import { blog } from "../../../dummydata";

// const Footer = () => {
//   return (
//     <>
//       <section className="newsletter bg-TealBlue text-white py-12 bg-Red">
//         <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center px-8">
//           <div className="left mb-8 lg:mb-0">
//             <h1 className=" font-bold mb-2 text-center lg:text-left sm:text-xs lg:text-xl ">
//               Newsletter - Stay tuned and get the latest update
//             </h1>
//           </div>
//           <div className="right flex items-center">
//             <input
//               type="text"
//               placeholder="Enter email address"
//               className="p-4 rounded-l w-64 md:w-96 text-gray-800 mb-4 lg:mb-0"
//             />
//             <button className="p-4 bg-customBlue text-white bg-Blue rounded-r" title="Send">
//               <i className="fa fa-paper-plane"></i>
//             </button>
//           </div>
//         </div>
//       </section>

//       <footer className="bg-customBlue text-white py-16 bg-Blue">
//         <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 px-8">
//           <div className="box">
//             <h1 className="text-4xl font-bold mb-4">
//               <span className="text-white">Sanjeed</span>
//               <span className="text-red-500">a!</span>
//             </h1>
//             <div className="flex justify-center lg:justify-start space-x-4 my-4">
//               <ul className="flex space-x-4">
//                 <li>
//                   <a
//                     href="https://www.facebook.com"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     title="Visit our Facebook page"
//                   >
//                     <i className="fab fa-facebook-f text-white hover:text-blue-500"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     href="https://www.instagram.com"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     title="Visit our Instagram page"
//                   >
//                     <i className="fab fa-instagram text-white hover:text-pink-500"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     href="https://www.youtube.com"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     title="Visit our YouTube page"
//                   >
//                     <i className="fab fa-youtube text-white hover:text-red-500"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     href="https://twitter.com"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     title="Visit our Twitter page"
//                   >
//                     <i className="fab fa-twitter text-white hover:text-blue-400"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     href="https://www.linkedin.com"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     title="Visit our LinkedIn page"
//                   >
//                     <i className="fab fa-linkedin-in text-white hover:text-blue-700"></i>
//                   </a>
//                 </li>
//               </ul>
//             </div>
//           </div>

//           <div className="box">
//             <h3 className="text-xl font-semibold mb-4">Explore</h3>
//             <ul>
//               <li className="mb-2">About Us</li>
//               <li className="mb-2">Services</li>
//               <li className="mb-2">Courses</li>
//               <li className="mb-2">Blog</li>
//               <li>Contact Us</li>
//             </ul>
//           </div>

//           <div className="box">
//             <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
//             <ul>
//               <li className="mb-2">Contact Us</li>
//               <li className="mb-2">Pricing</li>
//               <li className="mb-2">Terms & Conditions</li>
//               <li className="mb-2">Privacy</li>
//               <li>Feedback</li>
//             </ul>
//           </div>

//           <div className="box">
//             <h3 className="text-xl font-semibold mb-4">Recent Post</h3>
//             {blog.slice(0, 3).map((val, index) => (
//               <div className="items flex space-x-4 mb-4" key={index}>
//                 <div className="img w-16">
//                   <img src={val.cover} alt="" className="w-36 h-30 object-cover" />
//                 </div>
//                 <div className="text">
//                   <span className="block mb-1">
//                     <i className="fa fa-calendar-alt mr-1"></i>
//                     {val.date}
//                   </span>
//                   <span className="block mb-1">
//                     <i className="fa fa-user mr-1"></i>
//                     {val.type}
//                   </span>
//                   <h4 className="text-sm">{val.title.slice(0, 40)}...</h4>
//                 </div>
//               </div>
//             ))}
//           </div>

//           <div className="box">
//             <h3 className="text-xl font-semibold mb-4">Have a Question?</h3>
//             <ul>
//               <li className="mb-4">
//                 <i className="fa fa-map mr-2"></i>
//                 203 Fake St. Mountain View, San Francisco, California, USA
//               </li>
//               <li className="mb-4">
//                 <i className="fa fa-phone-alt mr-2"></i>
//                 +92 21 34832777 , 03132193503
//               </li>
//               <li>
//                 <i className="fa fa-paper-plane mr-2"></i>
//                 info@conductivity.com.pk
//               </li>
//             </ul>
//           </div>
//         </div>
//       </footer>

//       <div className="legal bg-gray-800 text-white py-4">
//         <div className="container mx-auto text-center">
//           <p>
//             Copyright ©2022 All rights reserved | This template is made with{" "}
//             <i className="fa fa-heart text-red-500"></i> by GorkhCoder
//           </p>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Footer;



import React from "react";
// import { blog } from "../../../dummydata";

const Footer = () => {
  
  const socialLinks = [
    { href: "https://www.facebook.com", icon: "fab fa-facebook-f", color: "hover:text-Blue" },
    { href: "https://www.instagram.com", icon: "fab fa-instagram", color: "hover:text-Pink" },
    { href: "https://www.youtube.com", icon: "fab fa-youtube", color: "hover:text-Red" },
    { href: "https://twitter.com", icon: "fab fa-twitter", color: "hover:text-Blue" },
    { href: "https://www.linkedin.com", icon: "fab fa-linkedin-in", color: "hover:text-Blue" },
  ];

  return (
    <>
      {/* Newsletter Section */}
      <section className="newsletter bg-gradient-to-r from-Red to-Blue text-white py-1 lg:py-10 ">
        <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center px-8">
          <div className="left mb-8 lg:mb-0">
            <h1 className="font-bold mb-2 text-center lg:text-left text-xl lg:text-xl sm:text-xs">
              Newsletter - Stay tuned and get the latest updates
            </h1>
          </div>
          <div className="right flex items-center">
            <input
              type="email"
              placeholder="Enter email address"
              aria-label="Email address input"
              className="p-4 rounded-l w-58 md:w-60 md:h-10 h-10 text-black mb-4 lg:mb-0 focus:outline-none"
            />
            <button
              className="p-2 bg-Red hover:bg-Blue text-white rounded-r -mt-4 lg:mt-0"
              title="Send"
              aria-label="Send email"
            >
              <i className="fa fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </section>

      
      {/* Footer Section */}
      <footer className="bg-[#041580] text-white py-8 px-4 lg:px-16">
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
    {/* Logo and Social Links */}
    <div className="text-center lg:text-left -ml-52 lg:ml-0">
      <h1 className="text-2xl lg:text-4xl font-bold">
        <span className="text-white">Sanjeed</span>
        <span className="text-Red">a!</span>
      </h1>
      <div className="flex justify-center lg:justify-start space-x-2 -mt-7  lg:mt-0 ml-96 lg:ml-auto">
        {socialLinks.map((link, index) => (
          <a
            key={index}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            title={`Visit our page on ${link.href}`}
            aria-label={`Visit our page on ${link.href}`}
            className="text-sm lg:text-lg hover:text-red-500 transition-colors"
          >
            <i className={`${link.icon} ${link.color}`}></i>
          </a>
        ))}
      </div>
    </div>

    {/* Quick Links */}
    <div className="text-center lg:text-left">
      <h3 className="text-lg font-semibold ">Quick Links</h3>
      <ul className="space-y-1 text-sm lg:text-sm lg:-ml-14 -ml-36 lg:mt-2 ">
        {["About Us", "Services", "Privacy", "Blog"].map((item, i) => (
          <li key={i} className="hover:text-Red transition-colors">
            {item}
          </li>
        ))}
      </ul>
      <ul className="space-y-1 lg:scroll-mt-1 -mt-24 text-sm lg:text-sm lg:ml-20 -mr-48 ">
        {["Terms & Conditions", "Feedback", "Have a Question?"].map((item, i) => (
          <li key={i} className="hover:text-Red transition-colors">
            {item}
          </li>
        ))}
      </ul>
    </div>

    {/* Contact Info */}
    <div className="text-center lg:text-left">
      <h3 className="lg:text-lg text:sm font-semibold mb-4 lg:ml-28">Contact Info</h3>
      <ul className="space-y-2">
        <li className="flex items-center justify-center lg:justify-start lg:text-sm text-xs lg:ml-28">
          <i className="fa fa-map-marker-alt mr-2"></i>
          203 Fake St. Mountain View, San Francisco, CA, USA
        </li>
        <li className="flex items-center justify-center lg:justify-start lg:text-sm text-xs lg:ml-28">
          <i className="fa fa-phone-alt mr-2"></i>
          +92 21 34832777 , 03132193503
        </li>
        <li className="flex items-center justify-center lg:justify-start lg:text-sm text-xs lg:ml-28">
          <i className="fa fa-paper-plane mr-2"></i>
          info@conductivity.com.pk
        </li>
      </ul>
    </div>
  </div>
</footer>

      {/* Legal Section */}
      <div className="bg-Blue text-white py-2">
        <div className="container mx-auto text-center ">
          <p className="text-xs lg:text-lg">
            {/* Copyright  */}
            &copy; {new Date().getFullYear()} 
            {/* All rights reserved | Made with */}
            {" "}
            <i className="fa fa-heart text-Red"></i> 
            {/* by GorkhCoder */}
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;

