

import React from "react";
import { servicesCard } from "../../dummydata";

const ServicesCard = () => {
  return (
    <section className="py-8 bg-gray-100">
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4 sm:px-6 lg:px-8">
        {servicesCard.map((val) => (
          <div
            className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col hover:shadow-xl transition-transform transform hover:scale-105"
            key={val.id}
          >
            {/* Image Section */}
            <div className="flex-shrink-0">
              <img
                src={val.cover}
                alt={val.ServicesName}
                className="w-full h-48 sm:h-60 object-cover"
              />
            </div>
            {/* Text Section */}
            <div className="flex-grow p-4 text-center">
              <h1 className="text-lg sm:text-xl font-semibold text-gray-800">
                {val.ServicesName}
              </h1>
              <p className="mt-2 text-gray-600 text-sm sm:text-base">
                {val.desc}
              </p>
            </div>
            {/* Button Section */}
            <div className="mt-auto p-4">
              <button className="w-full bg-TealBlue text-white py-2 px-4 rounded-lg hover:bg-customBlue transition duration-300">
                Click here
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesCard;
