import React from "react"; 
import Slider from "react-slick"; // Import react-slick
import "slick-carousel/slick/slick.css"; // Slick styles
import "slick-carousel/slick/slick-theme.css";

// Sample data for services
const servicesCard = [
  {
    id: 1,
    cover: "/courses/cc1.png",
    ServicesName:"Personality Assessment ",
    desc: "For enhanced data efficiency for recruiters and employers.",
  },
  {
    id: 2,
    cover: "/courses/c1.png",
    ServicesName: "3D CVs (AI Based-for Different JDs)",
    desc: "Easily create a standout CV tailored to your unique skills and experiences.",
  },
  {
    id: 3,
    cover: "/courses/c4.png",
    ServicesName: "Resume Services",
    desc: "Variety of desirable job openings, making it easy to apply and find opportunities that fit your skills.",
  },
  {
    id: 6,
    cover: "/courses/cc3.png",
    ServicesName: "Consultancy & Advisory Services",
    desc: "Personalized mock interview services to boost your interview success.",
  },
  {
    id: 5,
    cover: "/courses/c6.png",
    ServicesName: "Memberships",
    desc: "Designed for job seekers to improve and polish your skills to elevate your career.",
  },
  {
    id: 4,
    cover: "/courses/c5.png",
    ServicesName: "Offer Calculator",
    desc: "Evaluate and compare multiple job offers based on key factors like salary, benefits, and growth opportunities.",
  },
  {
    id: 7,
    cover: "/courses/cc9.png",
    ServicesName: "Mentoring Programs",
    desc: "Training and mentorship to help individuals gain the skills and confidence needed.",
  },
  {
    id: 8,
    cover: "/courses/cc8.png",
    ServicesName: "Career Placement Services",
    desc: "Tools to evaluate your skills and personality to identify suitable career paths.",
  },
  {
    id: 9,
    cover: "/courses/c2.png",
    ServicesName: "Counselling Services",
    desc: "Personalized strategies and insights to help you navigate challenges, enhance performance, and achieve your goals.",
  },
];

// Component for individual service card
const ServiceCard = ({ cover, ServicesName, desc }) => (
<div className="flex justify-center items-center py-24">
  <div className="container mx-auto px-2 py-3 text-center rounded-lg bg-gradient-to-r from-[#484dcd] to-[#e45c5c] lg:w-44 lg:h-44 sm:w-12 sm:h-12 md:w-12 md:h-20 w-32 h-36 absolute">
  <div className="service-card p-4 bg-[#ebecf4] rounded-lg shadow-md transform transition-all duration-300 hover:scale-105 hover:shadow-xl lg:w-40 lg:h-40 sm:w-12 sm:h-12 md:w-12 md:h-20 w-28 h-32  ">
    <img src={cover} alt={ServicesName} className="w-12 h-12 sm:w-4 sm:h-4 md:w-48 md:h-48 lg:w-16 lg:h-16 mx-auto -mt-2"/>
    <div className="size-40 ">
      <h3 className="relative -right-0 lg:-right-6 sm:text-xs w-20 text-center text-xs md:text-sm lg:text-sm xl:text-sm font-bold mt-0 lg:mr-4 mr-40">{ServicesName}</h3>
      {/* <p className="text-black mt-2 text-xs">{desc}</p> */}
    </div>
  </div></div>
  </div>
  
);


// Slider settings
const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,  // Default to 4 slides on large screens
  slidesToScroll: 1,
  autoplay: true, // Enable automatic sliding
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024, // Large screens: 2 slides per view
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768, // Tablet: 2 slides per view
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 640, // Small screens: 1 slide per view
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

// Component to display all services
const ServicesList = () => (
  <section className="services-section py-8 " >
    <div className="container lg:px-10">
      <h2 className="text-3xl font-bold text-center text-[#AD0217] mb-8 md:text-sm lg:text-4xl xl:text-4xl sm:text-xs mt-4 ">Explore Our  Services</h2>
      
      <Slider {...sliderSettings}>
        {servicesCard.map((service) => (
          
          <div key={service.id} className="px-2 text-center">
            <ServiceCard

              cover={service.cover}
              ServicesName={service.ServicesName}
              // desc={service.desc}
            />
          </div>
        ))}
      </Slider>
    </div>
  </section>
);

export default ServicesList;
