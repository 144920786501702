// import React from "react";
// import { blog } from "../../dummydata";
// import Title from "../common/title/Title";

// const Hblog = () => {
//   return (
//     <>
//       <section className="blog py-12 bg-gray-100">
//         <div className="container mx-auto px-4">
          
//           <Title subtitle="OUR BLOG" title="Recent From Blog" />
          
        
//           <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
//             {blog.slice(0, 3).map((val, index) => (
//               <div key={index} className="items bg-customBlue rounded-lg shadow-lg overflow-hidden">
                
//                 <div className="img">
//                   <img src={val.cover} alt={val.title} className="w-full h-48 object-cover" />
//                 </div>

                
//                 <div className="text p-6">
//                   <div className="admin flex justify-between text-sm text-gray-50 mb-4">
//                     <span>
//                       <i className="fa fa-user mr-2"></i>
//                       <label htmlFor="">{val.type}</label>
//                     </span>
//                     <span>
//                       <i className="fa fa-calendar-alt mr-2"></i>
//                       <label htmlFor="">{val.date}</label>
//                     </span>
//                     <span>
//                       <i className="fa fa-comments mr-2"></i>
//                       <label htmlFor="">{val.com}</label>
//                     </span>
//                   </div>
//                   <h1 className="text-xl font-semibold text-gray-800 mb-2">{val.title}</h1>
//                   <p className="text-gray-50 overflow-hidden text-ellipsis whitespace-nowrap max-h-12">{val.desc}</p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Hblog;

// import React from "react"; 
// import { blog } from "../../dummydata";
// import Title from "../common/title/Title";

// const Hblog = () => {
//   return (
//     <>
//       <section className="blog py-12 bg-white">
//         <div className="container mx-auto px-4 text-Red">
//           {/* Title component */}
//           <Title subtitle="OUR BLOG" title="Recent From Blog" />
          
//           {/* Blog grid */}
//           <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8 text-white">
//             {blog.slice(0, 3).map((val, index) => (
//               <div
//                 key={index}
//                 className="bg-Blue rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl"
//               >
//                 {/* Image */}
//                 <div className="img">
//                   <img
//                     src={val.cover}
//                     alt={val.title}
//                     className="w-full h-48 sm:h-56 lg:h-64 object-cover"
//                   />
//                 </div>

//                 {/* Text Content */}
//                 <div className="text p-4 sm:p-6">
//                   <div className="admin flex flex-wrap justify-between text-sm text-gray-50 mb-4">
//                     <span className="mb-2 sm:mb-0">
//                       <i className="fa fa-user mr-2"></i>
//                       <label>{val.type}</label>
//                     </span>
//                     <span className="mb-2 sm:mb-0">
//                       <i className="fa fa-calendar-alt mr-2"></i>
//                       <label>{val.date}</label>
//                     </span>
//                     <span className="mb-2 sm:mb-0">
//                       <i className="fa fa-comments mr-2"></i>
//                       <label>{val.com}</label>
//                     </span>
//                   </div>
//                   <h1 className="text-xl font-semibold text-gray-800 mb-2">{val.title}</h1>
//                   <p className="text-gray-50 text-sm sm:text-base overflow-hidden text-ellipsis whitespace-nowrap max-h-16 sm:max-h-20">
//                     {val.desc}
//                   </p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Hblog;

import React from 'react';
import { Link } from 'react-router-dom'; // To link to new pages
// import './BlogSection.css';
// Example Blog data
const blogs = [
  {
    id: 1,
    title: ' Interview Struggles',
    image: '/interview.jpeg', // Image path
    description: `Why do so many candidates struggle with Job Interviews? And what steps should be taken to improve the job interview process. 
    Job interviews are an important step in getting a job as they give the employers a….`,
   },
  {
    id: 2,
    title: '  Workplace Culture',
    image: '/culture.jpeg', // Image path
    description: `Should we stress upon the importance of Workplace Culture and promote ways to improve it?
Workplace culture plays a crucial role in shaping employee ....`,
   },
  {
    id: 3,
    title: '  Staying in Pakistan or Working Abroad',
    image: '/abroad.jpeg', // Image path
    description: `Is staying in Pakistan for work worth it? Would you get a better life and job opportunities abroad?
These are the questions that plague the mind of many...`,
   },
];

const Hblog = () => {
  return (
    <div className="blog-section bg-white py-8">
      <h2 className='text-Red font-semibold text-center py-4 text-4xl'>Blogs!</h2>
      <div className="blog-boxes blog-boxes flex flex-wrap justify-center gap-8 px-12">
        {blogs.map((blog) => (
          <div className="blog-box justify-between px-7 bg-Blue shadow-lg rounded-lg overflow-hidden w-72 h-72 lg:w-80 lg:h-96 transition-transform transform hover:scale-105" key={blog.id}>
            <img src={blog.image} alt={blog.title} className="blog-image justify-between w-64 h-36 lg:w-64 lg:h-48 object-cover py-3" />
            <h3 className="lg:text-lg text-sm font-bold text-center mt-3 text-white">{blog.title}</h3>
            <p className="lg:text-sm text-xs text-white mt-2">{blog.description}<label><Link to={`/blog/${blog.id}`} className="read-more-btn text-Red hover:text-white">Read More</Link>
            </label> </p>
             
          </div>
        ))}
      </div>
    </div>
  );};
  export default Hblog;