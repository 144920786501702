import React, { useState } from "react";
import Head from "./Head";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar ";

const Header = () => {
  const [click, setClick] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    console.log("Searching for:", searchQuery);
    // Implement search logic here
  };

  return (
    <>
      <Head />
      <header className="w-full h-12 bg-[#041580] py-6 relative z-10 shadow-md lg:h-12 lg:py-1">
        <nav className="flex justify-between items-center px-5 lg:px-10">
          {/* Logo Section */}
          {/* <div className="text-white text-lg font-bold">Logo</div> */}

          {/* Navigation Menu */}
          <div className="flex items-center space-x-4">
            <ul
             className={`${click ? "block absolute top-16 left-5 bg-[#0620cc] p-4 rounded-md shadow-lg" : "hidden lg:flex items-center space-x-6"}`}onClick={() => setClick(false)}
            >
              {/* Home */}
              <li className="relative group">
                <Link
                  to="/#Home"
                  className="text-white text-base px-4 py-2 flex items-center hover:bg-[#0620cc]"
                >
                  <div className="absolute left-0 bottom-0 w-full h-[2px] bg-Red scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100 group-focus-within:scale-x-100"></div>
                  <i className="fa fa-home mr-2" aria-hidden="true"></i> Home</Link>
                
                <div className="dropdown_menu hidden group-hover:block absolute top-full left-0 w-48 mt-2 bg-[#5768d7] shadow-lg z-10">
                <ul className="flex flex-col">
                <li>
                <Link  to="/about#About" className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"> About</Link>
              </li>
              <li>
              <Link to="contact/#Contact" className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center">
              <i className="fa fa-phone-square mr-2" aria-hidden="true"></i> Contact
              </Link>
              </li>
              <li>
                <Link to="pricing#Pricing"
                className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center">
                  Pricing
                  </Link>
                  </li>
                  </ul>
                 </div>
                 
               </li>
               
             {/* Services Dropdown */}
               <li className="relative group">
                 <Link to="/services#Services" className="text-white px-4 py-2 text-base flex items-center hover:bg-[#0620cc] ">
                 <div className="absolute left-0 bottom-0 w-full h-[2px] bg-Red scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100 group-focus-within:scale-x-100"></div>
                 <i class="fa fa-graduation-cap mr-2" aria-hidden="true"></i> Services
                 </Link>
                 <div className="dropdown_menu hidden group-hover:block absolute top-full left-0 w-48 mt-2 bg-[#5768d7] shadow-lg z-10">
                  
                   <ul className="flex flex-col">
                     <li>                       <Link
                        to="FinancialOffer#FinancialOffer"
                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
                       >
                         Offer Calculator
                       </Link>
                     </li>
                     <li>
                       <Link
                         to="StudentProfile#StudentProfile"
                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
                       >
                         Student Profile
                       </Link>
                     </li>
                     <li> 
                       <Link
                         to="/cv#3D-CVs"
                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
                       >
                         3D CVs
                       </Link>
                     </li>
                     <li>
                       <Link
                         to="/cv-upload#Upload_CV"
                         className="text-white px-4 py-2 text-sm hover:underline decoration-Red flex items-center"
                       >
                         Upload CV
                       </Link>
                     </li>
                   </ul>
                 </div>
               </li>
              {/* Team */}
              <li className="relative group">
              
                <Link
                  to="team#Team"
                  className="text-white px-4 py-2 text-base flex items-center  "
                >
                  <div className="absolute left-0 bottom-0 w-full h-[2px] bg-Red scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100 group-focus-within:scale-x-100 "></div>
                  
                  <i class="fa fa-users mr-2" aria-hidden="true"></i> Team
                </Link>
              </li>

              {/* Blog */}
              <li className="relative group">
                <Link
                  to="blogg"
                  className="text-white px-4 py-2 text-base flex items-center"
                >
                   <div className="absolute left-0 bottom-0 w-full h-[2px] bg-Red scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100 group-focus-within:scale-x-100"></div>
                   <i class="fa fa-file mr-2" aria-hidden="true"></i>Blog
                </Link>
              </li>
              {/* Registration Dropdown */}
               <li className="relative group">
                 <Link
                   to="/#Registration"
                   className="text-white px-4 py-2 text-base flex items-center hover:bg-[#0620cc]"
                 >
                    <div className="absolute left-0 bottom-0 w-full h-[2px] bg-Red scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100 group-focus-within:scale-x-100"></div>
                    <i class="fa fa-id-card mr-2" aria-hidden="true"></i>Registration
                 </Link>
                 <div className="dropdown_menu hidden group-hover:block absolute top-full left-0 w-48 mt-2 bg-[#5768d7] shadow-lg  z-10">
                   <ul className="flex flex-col">
                     <li>
                       <Link
                         to="Registration#Registration"
                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
                       >
                         I am Professional
                       </Link>
                     </li>
                     <li>
                       <Link
                         to="Registration#Registration"
                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
                       >
                         I am Job Seeker
                       </Link>
                     </li>
                     <li>
                       <Link
                         to="Registration#Registration"
                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
                       >
                         I am a Student
                       </Link>
                     </li>
                     <li>
                       <Link
                         to="Registration#Registration"
                         className="text-white px-4 py-2 text-sm hover:bg-[#0620cc] flex items-center"
                       >
                       I am a Mentor
                       </Link>
                     </li>
                     <li className="relative group">
                       <a
                         href="#Employer"
                         className="text-white block px-4 py-2 text-sm hover:bg-[#0620cc] items-center"
                       >
                         Employer <i className="fas fa-caret-right ml-2"></i>
                       </a>
                       <div className="hidden group-hover:block absolute top-0 left-full w-40 mt-2 bg-[#5768d7] shadow-lg  z-10">
                         <ul className="flex flex-col">
                           <li>
                             <Link
                               to="/JobForm#JobDescriptionForm"
                               className="text-white text-base px-4 py-2 flex items-center hover:bg-[#0620cc]"
                             >
                               Job Forms
                             </Link>
                           </li>
                         </ul>
                       </div>
                     </li>
                   </ul>
                 </div>
               </li>
            </ul>
          </div>
           
          
 <SearchBar/>
          {/* Login Button */}
          <div
            className="absolute -mr-20 right-20 text-white bg-Blue px-1 py-3  hover:bg-[#0620cc] xs:px-4 xs:py-3 xs:right-3"
            style={{ clipPath: "polygon(10% 0, 100% 0, 100% 100%, 0 100%)" }}
          >
            <a href="/logo" className="text-white px-3 py-3 rounded-md">
              Log In
            </a>
          </div>

          {/* Hamburger Menu */}
          <button
            className="lg:hidden absolute  text-white text-3xl p-2 px-3 mr-96"
            // className="absolute -mr-20 right-20 text-white bg-Blue px-1 py-3  hover:bg-[#0620cc] xs:px-4 xs:py-3 xs:right-3"
            onClick={() => setClick(!click)}
            aria-label={click ? "Close navigation" : "Open navigation"}
          >
            {click ? <i className="fa fa-times fa-xs"></i> : <i class="fa fa-align-justify fa-xs" aria-hidden="true"></i>}
          </button>
          {/* Search Bar */}
          <div className="hidden lg:flex items-center space-x-2 relative right-16 ">
          <input
    type="text"
    className="flex-1 px-4 py-2 rounded-md text-sm bg-[#f2f3f6] text-black focus:outline-none"
    placeholder="Search..."
    value={searchQuery}
    onChange={( handleSearch) => setSearchQuery( handleSearch.target.value)}
  />
            <button
              onClick={handleSearch}
              className="absolute right-3 top-0 mt-2 mr-4 text-x"
            >
            <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </nav>

       
        

      </header>
    </>
  );
};

export default Header;
