
// import React from 'react';

// const Login = () => {
//   return (
//     <div
//       className="flex items-center justify-center min-h-screen bg-cover bg-center "
//       // style={{ backgroundImage: "url('ap.img.png')" }}
//     >
//       <div className="bg-white bg-opacity-80 p-8 rounded-lg shadow-md shadow-darkBlue h-full max-w-sm">
//         <h2 className="text-2xl font-bold text-center mb-6 text-TealBlue">Login</h2>
//         <form action="your-login-processing-url" method="post" className="space-y-4">
//           <div>
//             <label htmlFor="username" className="block font-semibold mb-1 text-TealBlue">Username</label>
//             <input
//               type="text"
//               id="username"
//               name="username"
//               required
//               className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-customBlue"
//             />
//           </div>
//           <div>
//             <label htmlFor="password" className="block font-semibold mb-1 text-TealBlue">Password</label>
//             <input
//               type="password"
//               id="password"
//               name="password"
//               required
//               className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-customBlue"
//             />
//           </div>
//           <div className="flex items-center justify-between text-TealBlue">
//             <label className="flex items-center">
//               <input type="checkbox" className="mr-2" /> Remember me
//             </label>
//             <a href="/forgot-password" className="text-sm text-TealBlue hover:underline">Forgot password?</a>

//           </div>
//           <input
//             type="submit"
//             value="Login"
//             className="w-full bg-TealBlue text-white py-2 rounded-md hover:bg-customBlue transition-colors cursor-pointer"
//           />
//         </form>
//         <div className="text-center mt-4">
//           <p className="text-sm text-TealBlue ">
//             Don't have an account?{' '}
//             <a href="/Register" className="text-TealBlue hover:underline">Register</a>
//           </p>
//         </div>
//         <div className="flex gap-4 justify-center mt-6">
//       <i className="fab fa-google text-3xl text-TealBlue  hover:text-red-500 transition-colors cursor-pointer"></i>
//       <i className="fab fa-facebook text-3xl text-TealBlue  hover:text-blue-600 transition-colors cursor-pointer"></i>
//     </div>
//       </div>
//     </div>
//   );
// };

// export default Login;
import React, { useState } from 'react';

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-cover bg-center py-12 px-4 sm:px-6 lg:px-8"
      // style={{ backgroundImage: "url('ap.img.png')" }}
    >
      <div className="bg-white lg:p-8 p-2 rounded-lg shadow-md shadow-darkBlue lg:h-full lg:max-w-sm max-w-62 h-auto ">
        {/* Tab Navigation */}
        <div className="flex justify-center mb-6">
          <button
            className={`px-4 py-2 rounded-t-lg ${isLogin ? 'bg-Blue text-white' : 'text-Red font-bold'}`}
            onClick={() => setIsLogin(true)}
          >
            Login
          </button>
          <button
            className={`px-4 py-2 rounded-t-lg ${!isLogin ? 'bg-Blue text-white' : 'text-Red font-bold'}`}
            onClick={() => setIsLogin(false)}
          >
            Sign Up
          </button>
        </div>

        {isLogin ? (
          // Login Form
          <form action="your-login-processing-url" method="post" className="space-y-4">
            <h2 className="text-2xl font-bold text-center mb-4 text-Red">Login</h2>
            <div>
              <label htmlFor="username" className="block font-semibold mb-1 text-Blue">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                required
                className="w-full px-3 py-2 border border-gray rounded-md focus:outline-none focus:ring-2 focus:ring-black"
              />
            </div>
            <div>
              <label htmlFor="password" className="block font-semibold mb-1 text-Blue">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                required
                className="w-full px-3 py-2 border border-gray rounded-md focus:outline-none focus:ring-2 focus:ring-black"
              />
            </div>
            <div className="flex items-center justify-between text-Blue">
              <label className="flex items-center">
                <input type="checkbox" className="mr-1" /> Remember me
              </label>
              <a href="/Forgot-password" className="text-sm text-Red hover:underline ml-12"> Forgot password?</a>
            </div>
            <input
              type="submit"
              value="Login"
              className="w-full bg-Blue text-white py-2 rounded-md hover:bg-Red transition-colors cursor-pointer"
            />
          </form>
        ) : (
          // Sign-Up Form
          <form action="your-signup-processing-url" method="post" className="space-y-4">
            <h2 className="text-2xl font-bold text-center mb-4 text-Red">Sign Up</h2>
            <div>
              <label htmlFor="name" className="block font-semibold mb-1 text-Blue">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                required
                className="w-full px-3 py-2 border border-gray rounded-md focus:outline-none focus:ring-2 focus:ring-black"
              />
            </div>
            <div>
              <label htmlFor="email" className="block font-semibold mb-1 text-Blue">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                required
                className="w-full px-3 py-2 border border-gray rounded-md focus:outline-none focus:ring-2 focus:ring-black"
              />
            </div>
            <div>
              <label htmlFor="password" className="block font-semibold mb-1 text-Blue">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                required
                className="w-full px-3 py-2 border border-gray rounded-md focus:outline-none focus:ring-2 focus:ring-black"
              />
            </div>
            <input
              type="submit"
              value="Sign Up"
              className="w-full bg-Blue text-white py-2 rounded-md hover:bg-Red transition-colors cursor-pointer"
            />
          </form>
        )}

        {/* Social Login */}
        <div className="flex gap-4 justify-center mt-6">
          <i className="fab fa-google text-3xl text-Red hover:text-Blue transition-colors cursor-pointer"></i>
          <i className="fab fa-facebook text-3xl text-Blue hover:text-Red transition-colors cursor-pointer"></i>
        </div>
      </div>
    </div>
  );
};

export default Auth;
