
import React, { useState } from 'react';

const JobDescriptionForm = () => {
  const [page, setPage] = useState(1);
  const nextPage = () => setPage((prev) => Math.min(prev + 1, 3));
  const prevPage = () => setPage((prev) => Math.max(prev - 1, 1));
  return (
  // <div className="min-h-screen flex items-center justify-center bg-cover bg-no-repeat"
  
  // >
  //   <div className="p-10">
  //     <div className="bg-white p-8 rounded-lg shadow-lg shadow-darkBlue h-full lg:max-w-3xl max-w-md mx-auto py-12 px-4 sm:px-6 lg:px-8">
  //       <table className="w-full  text-white">
  //         <tbody>
  //           {page === 1 && (
  //             <>
  //             <h1 className="text-Red lg:text-3xl  font-semibold mb-6 text-lg">Job Success Form</h1>
  //             <tr className="border-b border-Red">
  //             <td className="section py-2 text-Blue font-semibold lg:text-base text-sm">Position Information</td>
  //             </tr>
  //             <tr>
  //               <td className="py-4 text-Blue text-base">Position Title:</td>
  //             <td>
  //             <input className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring-2 focus:ring-Blue "
  //             type="text"
  //             placeholder="Enter the position title"/>
  //             </td>
  //             </tr>
  //             <tr>
  //               <td className="py-4 text-base text-Blue">
  //                 <label htmlFor="division">Division/Department:</label>
  //                 </td>
  //                 <td>
  //                 <input id="division"
  //                 className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring-2 focus:ring-Blue"
  //                 type="text"
  //                 title="Enter the division or department"
  //                 placeholder="Enter the division/department"/>
  //               </td>
  //             </tr>
  //             <tr>
  //               <td className="py-4 text-base text-Blue">
  //                <label htmlFor="reportsTo">Reports To:</label>
  //               </td>
  //               <td>
  //                 <input id="reportsTo"
  //                 className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring-2 focus:ring-Blue"
  //                 type="text"
  //                 title="Enter the reporting manager or supervisor"
  //                 placeholder="Enter who this position reports to"/>
  //               </td>
  //             </tr>
  //             <tr>
  //               <td className="py-2 text-base text-Blue">
  //                 <label htmlFor="spanOfControl">Span of Control:</label>
  //               </td>
  //               <td>
  //                 <input id="spanOfControl"
  //                 className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring-2 focus:ring-Blue"
  //                 type="text"
  //                 title="Enter the span of control"
  //                 placeholder="Describe the span of control"/>
  //               </td>
  //             </tr>
  //             <tr>
  //               <td className="py-4 text-base text-Blue">
  //                 <label htmlFor="jobGrade">Job Grade:</label>
  //               </td>
  //               <td>
  //                 <input id="jobGrade"
  //                 className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring-2 focus:ring-Blue"
  //                 type="text"
  //                 title="Enter the job grade"
  //                 placeholder="Enter job grade"/>
  //               </td>
  //             </tr>
  //             <tr>
  //               <td className="py-4 text-base text-Blue">
  //                 <label htmlFor="jobLocation">Job Location:</label>
  //               </td>
  //               <td>
  //                 <input id="jobLocation"
  //                 className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring-2 focus:ring-Blue"
  //                 type="text"
  //                 title="Enter the job location"
  //                 placeholder="Enter job location"/>
  //               </td>
  //             </tr>
  //             <tr>
  //               <td className="py-4 text-base text-Blue">
  //                 <label htmlFor="departmentGoals">Department Goals:</label>
  //               </td>
  //               <td>
  //                 <textarea id="departmentGoals" rows="2"
  //                 className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring-2 focus:ring-Blue"
  //                 title="Enter department goals"
  //                 placeholder="Enter the department goals">
  //                 </textarea>
  //               </td>
  //             </tr>
  //             </>
  //           )}

  <div className="p-4 sm:p-6 md:p-8 lg:p-12 bg-white min-h-screen">
  <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden shadow-darkBlue">
    <div className="p-6">
      <table className="table-auto w-full">
        <tbody>
          {page === 1 && (
            <>
              <h1 className="text-2xl md:text-3xl font-bold text-center mb-6 text-Red">
                Job Success Form
              </h1>
              <tr className="border-b border-Red">
                <td className="text-lg font-semibold py-2 text-Blue">Position Information</td>
              </tr>
              <tr>
                <td className="py-2">
                  <label htmlFor="positionTitle" className="font-medium text-Blue">
                    Position Title:
                  </label>
                </td>
                <td className="py-2">
                  <input
                    id="positionTitle"
                    className="w-full border border-black rounded-md p-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-Blue"
                    type="text"
                    placeholder="Enter the position title"
                  />
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  <label htmlFor="division" className="font-medium text-Blue">
                    Division/Department:
                  </label>
                </td>
                <td className="py-2">
                  <input
                    id="division"
                    className="w-full border border-black rounded-md p-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-Blue"
                    type="text"
                    placeholder="Enter the division/department"
                  />
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  <label htmlFor="reportsTo" className="font-medium text-Blue">
                    Reports To:
                  </label>
                </td>
                <td className="py-2">
                  <input
                    id="reportsTo"
                    className="w-full border border-black rounded-md p-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-Blue"
                    type="text"
                    placeholder="Enter who this position reports to"
                  />
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  <label htmlFor="spanOfControl" className="font-medium text-Blue">
                    Span of Control:
                  </label>
                </td>
                <td className="py-2">
                  <input
                    id="spanOfControl"
                    className="w-full border border-black rounded-md p-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-Blue"
                    type="text"
                    placeholder="Describe the span of control"
                  />
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  <label htmlFor="jobGrade" className="font-medium text-Blue">
                    Job Grade:
                  </label>
                </td>
                <td className="py-2">
                  <input
                    id="jobGrade"
                    className="w-full border border-black rounded-md p-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-Blue"
                    type="text"
                    placeholder="Enter job grade"
                  />
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  <label htmlFor="jobLocation" className="font-medium text-Blue">
                    Job Location:
                  </label>
                </td>
                <td className="py-2">
                  <input
                    id="jobLocation"
                    className="w-full border border-black rounded-md p-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-Blue"
                    type="text"
                    placeholder="Enter job location"
                  />
                </td>
              </tr>
              <tr>
                <td className="py-2">
                  <label htmlFor="departmentGoals" className="font-medium text-Blue">
                    Department Goals:
                  </label>
                </td>
                <td className="py-2">
                  <textarea
                    id="departmentGoals"
                    rows="2"
                    className="w-full border border-black rounded-md p-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-Blue"
                    placeholder="Enter the department goals"
                  ></textarea>
                </td>
              </tr>
            </>
          )}
       

            {/* {page === 2 && (
              <>
              <h1 className="text-Blue lg:text-3xl font-semibold mb-6 text-base">Success Factors at Job</h1>
              <tr className="border-b border-Red">

              </tr>
              <table>
                <tbody>
                <tr>
                    <td className="py-9 lg:text-base text-sm text-Blue">
                      <label htmlFor="KeyReportAreas">Key Report Areas:</label>
                    </td>
                <td>
                  <textarea id="KeyReportAreas" rows="2"
                  className="lg:w-full w-64 lg:px-3 lg:py-2 px-1 border border-black rounded focus:outline-none focus:ring-2 focus:ring-Blue m-1"
                  title="Enter department Areas"
                  placeholder="Enter the department Areas">

                  </textarea>
                </td>
                </tr>
              <tr>
                <td className=" py-2 lg:text-base text-sm text-Blue">
                  <label htmlFor="jd">JD (4 to 5 lines):</label>
                </td>
                <td>
                  <textarea id="jd" rows="4"
                  className="lg:w-full w-64 lg:px-3 lg:py-2 border border-black rounded focus:outline-none focus:ring-2 focus:ring-Blue"
                  title="Enter job description"
                  placeholder="Enter job description"/>
                </td>
              </tr>
              <tr>
                <td className="py-4 lg:text-base text-sm text-Blue">
                  <label htmlFor="projectsRequired">Projects Required:</label>
                </td>
                <td>
                  <textarea id="projectsRequired" rows="2"
                  className="lg:w-full lg:px-3 lg:py-2 w-64 border border-black rounded focus:outline-none focus:ring-2 focus:ring-Blue"
                  title="Enter the projects required for this position"
                  placeholder="Enter required projects"/>
                </td>
              </tr>
              <tr>
                <td className="py-4 lg:text-base text-xs text-Blue">
                  <label htmlFor="majorChallenges">Major Challenges/Problems</label>
                </td>
                <td>
                  <div className="overflow-x-auto p-4 ">
                    <table className="lg:min-w-full min-w-48 bg-white shadow-md shadow-darkBlue rounded-lg">
                      <thead>
                        <tr className="bg-Blue text-white uppercase lg:text-sm text-xs leading-normal">
                        <th className="py-2 px-2 lg:py-3 lg:px-6 text-center">Work</th><th className="py-2 px-2 lg:py-3 lg:px-6 text-left">Team</th>
                        <th className="py-2 px-2 lg:py-3 lg:px-6 text-left">Process</th>
                        <th className="py-2 px-2 lg:py-3 lg:px-6 text-left ">Client</th>
                        </tr>
                      </thead>
                      <tbody className="text-black lg:text-sm text-xs">
                        {[...Array(3)].map((_, rowIndex) => (
    <tr key={rowIndex} className="border-b border-gray hover:bg-gray2">
      {["Work", "Team", "Process", "Client"].map((placeholder, colIndex) => (
        <td key={colIndex} className="py-3 px-6">
          <input
            type="text"
            className="lg:w-full lg:px-3 lg:py-2 w-6 border border-gray lg:rounded-md focus:outline-none focus:ring-2 focus:ring-blue"
            title={`Enter ${placeholder.toLowerCase()}-related challenge`}
            placeholder={`Enter ${placeholder.toLowerCase()} challenge`}
          />
        </td>
      ))}
    </tr>
  ))}
</tbody>

                        </table>
                        </div>
                      </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )} */}
              {page === 2 && (
  <>
    <div className="p-6">
   
      <h1 className="text-xl md:text-3xl font-bold text-center mb-6 text-Blue border-b border-Red ">
        
        Success Factors at Job
      </h1>
      
      <table className="table-auto w-full border-collapse">
        <tbody>
          <tr>
            <td className="py-2">
              <label htmlFor="KeyReportAreas" className="font-medium lg:text-lg text-sm text-Blue">
                Key Report Areas:
              </label>
            </td>
            <td className="py-2">
              <textarea
                id="KeyReportAreas"
                rows="2"
                className="lg:w-full w-72
                 border border-black rounded-md p-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-Blue"
                title="Enter department areas"
                placeholder="Enter the department areas"
              ></textarea>
            </td>
          </tr>
          <tr>
            <td className="py-2">
              <label htmlFor="jd" className="font-medium lg:text-lg text-sm text-Blue">
                JD (4 to 5 lines):
              </label>
            </td>
            <td className="py-2">
              <textarea
                id="jd"
                rows="4"
                className="lg:w-full w-72 border border-black rounded-md p-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-Blue"
                title="Enter job description"
                placeholder="Enter job description"
              />
            </td>
          </tr>
          <tr>
            <td className="py-2">
              <label htmlFor="projectsRequired" className="font-medium lg:text-lg text-sm text-Blue">
                Projects Required:
              </label>
            </td>
            <td className="py-2">
              <textarea
                id="projectsRequired"
                rows="2"
                className="lg:w-full w-72 border border-black rounded-md p-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-Blue"
                title="Enter the projects required for this position"
                placeholder="Enter required projects"
              ></textarea>
            </td>
          </tr>
          <tr>
            <td className="py-20 align-top">
              <label htmlFor="majorChallenges" className="font-medium lg:text-lg text-sm text-Blue">
                Major Challenges/Problems:
              </label>
            </td>
            <td className="py-2">
              <div className="overflow-x-auto">
                <table className="table-auto lg:w-full w-2 border border-black rounded-md text-sm sm:text-base text-white ">
                  <thead>
                    <tr className="bg-Blue">
                      <th className="px-4 py-2 text-left text-white">Work</th>
                      <th className="px-4 py-2 text-left">Team</th>
                      <th className="px-4 py-2 text-left">Process</th>
                      <th className="px-4 py-2 text-left">Client</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(3)].map((_, rowIndex) => (
                      <tr key={rowIndex} className="border-t">
                        {["Work", "Team", "Process", "Client"].map(
                          (placeholder, colIndex) => (
                            <td key={colIndex} className="px-4 py-2">
                              <input
                                type="text"
                                className="w-full border border-black rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-Blue"
                                title={`Enter ${placeholder.toLowerCase()}-related challenge`}
                                placeholder={`Enter ${placeholder.toLowerCase()} challenge`}
                              />
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </>
)}

              {/* {page === 3 && (
              <>
              <h1 className="text-Blue text-3xl font-semibold mb-6">Required Expertise for Success</h1>
              <tr className="border-b border-Red"></tr>
              <tr>
            <td className="py-4 text-Blue">
              <label htmlFor="educationRequired">Education Required:</label>
            </td>
            <td>
              <input id="educationRequired"
              className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring-2 focus:ring-Blue"
              type="text"
              title="Enter the education requirement"
              placeholder="Enter education requirement"/>
            </td>
          </tr>
          <tr>
            <td className="py-4 text-Blue">
              <label htmlFor="experienceRequired">Trainings/Certification</label>
            </td>
            <td>
              <input id="experienceRequired"
              className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring-2 focus:ring-Blue"
              type="text"
              title="Enter the experience required"
              placeholder="Enter experience requirement"/>
            </td>
          </tr>
          <tr>
            <td className="py-4 text-Blue">
              <label htmlFor="experienceRequired">Experience Required:</label>
            </td>
            <td>
              <input
              id="experienceRequired"
              className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring-2 focus:ring-Blue"
              type="text"
              title="Enter the experience required"
              placeholder="Enter experience requirement"/>
            </td>
          </tr>
          <tr>
            <td className="py-4 text-Blue">
              <label htmlFor="personalityRequired">Personality Required:</label>
            </td>
            <td>
              <textarea id="personalityRequired" rows="2"
              className="w-full px-3 py-2 border border-black rounded focus:outline-none focus:ring-2 focus:ring-Blue"
              title="Enter the required personality traits"
              placeholder="Enter required personality traits">

              </textarea>
            </td>
          </tr>
          </>)} */}
          {page === 3 && (
  <>
    <div className="p-6">
      <h1 className="text-2xl md:text-3xl font-bold text-center mb-6 text-Blue border-b border-Red">
        Required Expertise for Success
      </h1>
      <table className="table-auto w-full border-collapse">
        <tbody>
          <tr>
            <td className="py-2">
              <label htmlFor="educationRequired" className="font-medium text-Blue">
                Education Required:
              </label>
            </td>
            <td className="py-2">
              <input
                id="educationRequired"
                className="w-full border border-black rounded-md p-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-Blue"
                type="text"
                title="Enter the required education level"
                placeholder="Enter education requirements"
              />
            </td>
          </tr>
          <tr>
            <td className="py-2">
              <label htmlFor="trainingsRequired" className="font-medium text-Blue">
                Trainings/Certification:
              </label>
            </td>
            <td className="py-2">
              <input
                id="trainingsRequired"
                className="w-full border border-black rounded-md p-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-Blue"
                type="text"
                title="Enter the required trainings or certifications"
                placeholder="Enter training or certification requirements"
              />
            </td>
          </tr>
          <tr>
            <td className="py-2">
              <label htmlFor="experienceRequired" className="font-medium text-Blue">
                Experience Required:
              </label>
            </td>
            <td className="py-2">
              <input
                id="experienceRequired"
                className="w-full border border-black rounded-md p-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-Blue"
                type="text"
                title="Enter the required experience level"
                placeholder="Enter experience requirements"
              />
            </td>
          </tr>
          <tr>
            <td className="py-2 align-top">
              <label htmlFor="personalityRequired" className="font-medium text-Blue">
                Personality Required:
              </label>
            </td>
            <td className="py-2">
              <textarea
                id="personalityRequired"
                rows="2"
                className="w-full border border-Black rounded-md p-2 text-sm sm:text-base focus:outline-none focus:ring-2 focus:ring-Blue"
                title="Enter the personality traits required for success"
                placeholder="Describe the personality requirements"
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </>
)}

        </tbody>
        </table>
        <div className="flex justify-between mt-6">
          {page > 1 && (
            <button onClick={prevPage}
            className="bg-Blue text-white font-semibold py-2 px-4 rounded-md hover:bg-Red transition-all duration-300">
              Previous
            </button>
          )}
          {page < 3 ? (
            <button onClick={nextPage}
            className="bg-Blue text-white font-semibold py-2 px-4 rounded-md hover:bg-Red transition-all duration-300">
              Next
            </button>
             ) : (
              <button
                className="bg-Blue text-white font-semibold py-2 px-4 rounded-md hover:bg-Red transition-all duration-300"
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDescriptionForm;
