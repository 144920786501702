// import React from "react";
// import { blog } from "../../dummydata";

// const BlogCard = () => {
//   return (
//     <>
//       {blog.map((val, index) => (
//         <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col">
//           <div className="img">
//             <img src={val.cover} alt={val.title} className="w-full h-48 object-cover" />
//           </div>
//           <div className="p-6 flex flex-col">
//             <div className="admin flex justify-between text-sm text-gray-500 mb-4">
//               <span className="flex items-center">
//                 <i className="fa fa-user mr-2"></i>
//                 <label>{val.type}</label>
//               </span>
//               <span className="flex items-center">
//                 <i className="fa fa-calendar-alt mr-2"></i>
//                 <label>{val.date}</label>
//               </span>
//               <span className="flex items-center">
//                 <i className="fa fa-comments mr-2"></i>
//                 <label>{val.com}</label>
//               </span>
//             </div>
//             <h1 className="text-xl font-semibold text-gray-800 mb-2">{val.title}</h1>
//             <p className="text-gray-600">{val.desc}</p>
            
//           </div>
//         </div>
//       ))}
//     </>
//   );
// };

// export default BlogCard;

import React from 'react';
import { Link } from 'react-router-dom'; // To link to new pages
// import './BlogSection.css';
// Example Blog data
const blogs = [
  {
    id: 1,
    title: ' Interview Struggles',
    image: '/interview.jpeg', // Image path
    description: `Why do so many candidates struggle with Job Interviews? And what steps should be taken to improve the job interview process. 
    Job interviews are an important step in getting a job as they give the employers a chance to see if the candidate is suitable for the position and if their interests align with the company….`,
   },
  {
    id: 2,
    title: '  Workplace Culture',
    image: '/culture.jpeg', // Image path
    description: `Should we stress upon the importance of Workplace Culture and promote ways to improve it?
Workplace culture plays a crucial role in shaping employee behaviour, satisfaction, engagement, and overall organizational success....`,
   },
  {
    id: 3,
    title: '  Staying in Pakistan or Working Abroad',
    image: '/abroad.jpeg', // Image path
    description: `Is staying in Pakistan for work worth it? Would you get a better life and job opportunities abroad?
These are the questions that plague the mind of many Pakistanis especially the current mixture of Gen Z and millennials. Deciding between the two is not ...`,
   },
];

const BlogCard = () => {
  return (
    <div className="blog-section bg-white py-8">
      <h2 className='text-Red font-semibold text-center py-4 text-4xl'>Blogs!</h2>
      <div className="blog-boxes blog-boxes flex flex-wrap justify-center gap-8 px-12">
        {blogs.map((blog) => (
          <div className="blog-box px-7 bg-white shadow-lg rounded-lg overflow-hidden w-80 transition-transform transform hover:scale-105" key={blog.id}>
            <img src={blog.image} alt={blog.title} className="blog-image w-full h-48 px-2 object-cover" />
            <h3 className="text-lg font-bold text-center text-Blue">{blog.title}</h3>
            <p className="text-sm text-black mt-2">{blog.description}<label><Link to={`/blog/${blog.id}`}   className="read-more-btn text-Blue hover:text-Red">Read More</Link>
            </label> </p>
             
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogCard;
