import React from "react";
import { awrapper } from "../../dummydata";

const Awrapper = () => {
  return (
    <>
      <section className="py-12 bg-gradient-to-r from-[#484dcd] to-[#e45c5c]">
        <div className="container mx-auto grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-6 px-4 sm:px-6 lg:px-36 lg:inline-center">
          {awrapper.map((val, index) => (
            <div
              key={index}
              className="box flex items-center bg-Blue p-4 sm:p-6 shadow-md rounded-lg transition-transform transform hover:scale-105 hover:shadow-lg lg:w-72 lg:h-16 w-28 h-12"
            >
              {/* Image */}
              <div className="img mr-4">
                <img
                  src={val.cover}
                  alt={val.title}
                  className="w-7 h-7 sm:w-14 sm:h-14 lg:w-16 lg:h-16"
                />
              </div>
              {/* Text */}
              <div className="text-white">
                <h1 className="text-xs sm:text-xs lg:text-xl xl:text-2xl font-bold">
                  {val.data}
                </h1>
                <h3 className="text-xs lg:text-sm text-white">
                  {val.title}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </section>
      <div className="margin"></div>
    </>
  );
};

export default Awrapper;
