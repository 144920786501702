import "./App.css"
import Header from './components/common/heading/Header'
// import { BrowserRouter as Router, Switch, Route , } from "react-router-dom"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./components/about/About"
import Home from "./components/home/hero/Home"
import ServicesHome from "./components/allServices/ServicesHome.jsx";
import Team from "./components/common/Team/Team.jsx"
// import Blog from "./components/Blog/Blog.jsx"
import Testimonal from "./components/home/testimonal/Testimonal.jsx"
import Pricing from "./components/pricing/Pricing.jsx"
import Contact from "./components/contact/Contact"
import Footer from "./components/common/footer/footer.jsx"
import Login from "./components/logo/login.jsx"
import CVUploadForm from "./components/cv-upload/CVUploadForm.jsx"
import RegistrationForm from "./components/registration/RegistrationForm.jsx"
import JobDescriptionForm from "./components/JobForm/JobDescriptionForm.jsx"
import FinancialOffer from "./components/FinancialOffer/FinancialOffer.jsx"
import RegistHome from "./components/registration/RegistHome.js"
import PersonalInformationForm from "./components/registration/Information.js"
import PersonalityAssessment from "./components/registration/PersonalityAssessment.jsx"
import CVs from "./components/common/cv/CVs.js";
import BlogCard from "./components/Blog/BlogCard.jsx";
import BlogPage from "./components/Blog/BlogPage.jsx";
import TestimonalPage from "./components/home/testimonal/TestimonalPg.jsx";
import ForgotPassword from "./components/logo/ForgotPassword.jsx";
import StudentProfile from "./components/allServices/StudentProfile.jsx";
// import ForgotPassword from './components/ForgotPassword';


function App()  {
  return (
  <>
 
    <Router>
       <Header/>
       <Routes>
        <Route path='/'  element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/Services' element={<ServicesHome/>}/>
        <Route path='/team'  element={<Team/>}/>
        <Route path='/blogg' element={<BlogCard/>} />
        <Route path='/blog/:id' element={<BlogPage/>} />

        <Route path="/testimonal" element={<Testimonal/>} />
        <Route path='/Testimonal/:id' element={<TestimonalPage/>} />
        <Route path='/pricing' element={<Pricing/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/cv-upload' element={<CVUploadForm/>} />
        <Route path='/logo' element={<Login/>} />
        <Route path='/registration' element={<RegistrationForm/>} />
        <Route path='/JobForm' element={<JobDescriptionForm/>} />
        <Route path='/FinancialOffer' element={<FinancialOffer/>} />
        <Route path='/regist-home' element={<RegistHome/>} />
        <Route path='/Information' element={<PersonalInformationForm/>} />
        <Route path='/PersonalityAssessment' element={<PersonalityAssessment/>} />
        <Route path='/cv' element={<CVs/>} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/StudentProfile" element={<StudentProfile />} />
       
        </Routes>
        
      <Footer/>
    </Router>
  </>
  )
}

export default App;