import React from 'react'
// import Title from '../common/title/Title'
// import { homeAbout } from '../../dummydata'

import Awrapper from './AWrapper'

const AboutCard = () => {
  return (
    <>
      <section className="py-5 bg-[#dcdce8] lg:top-12">
     
      <h1 className="flex items-baseline space-x-1 w-screen md:w-1/2 mt-2 md:mt-0 md:place-content-center lg:w-4/5 lg:m-auto">
            <span className="text-Red font-bold text-3xl">Sanjeed</span>
            <span className="text-[#081b9c] font-bold text-2xl ">a!</span>
          </h1>
        <div className="container mx-auto flex md:flex-row px-4 sm:px-6">
          
              <div className="video-container w-screen md:w-1/2 mt-2 md:mt-0 md:pl-1 lg:w-4/5 lg:m-auto">
      <video
       src="/Video.mp4" type="Video/v.mp4" 
        controls
        autoPlay
        loop
        muted
        className="w-full h-auto rounded-lg shadow-lg">
      
        Your browser does not support the video tag.
      </video>
    </div>
          {/* Text Section */}
          {/* <div className="w-full md:w-1/2 mt-8 md:mt-0 md:pl-1"> */}
            {/* <Title title="About us"/> */}
            {/* <div className="mt-6 space-y-6">
              {homeAbout.map((val) => (
                <div key={val.title} className="flex flex-col sm:flex-row items-center sm:items-start space-y-4 sm:space-y-0 sm:space-x-4"> */}
                  
                  {/* Image */}
                  {/* <div className="w-24 h-24 sm:w-20 sm:h-20">
                    <img
                      src={val.cover}
                      alt={val.title}
                      className="w-full h-full"
                    />
                  </div> */}
                  
                  {/* Title and Description */}
                  {/* <div className="text-center sm:text-left">
                    <h2 className="text-lg font-semibold">{val.title}</h2>
                    <p className="text-gray-600">{val.desc}</p>
                  </div> */}
                {/* </div>
              ))}
            </div> */}
          {/* </div> */}
        </div>
      </section>
      <Awrapper />
    </>
  )
}

export default AboutCard
