// import React from 'react';


// const Hero = () => {
//   return (
//     <>
//       <section
//         className="hero bg-cover h-screen flex items-center justify-center"
//         style={{
//           backgroundImage: "url('hero.png')",
//           backgroundPosition: "center center",  
//           backgroundSize: "cover", 
//         }}
//       >
//         <div className="container mx-auto px-4 sm:px-6 lg:px-8 ">
//           <div className="row flex flex-col items-center text-center text-white space-y-6 ">
//             <h1 className='text-2xl sm:text-3xl md:text-4xl font-bold mt-2'>Connecting Passion with Profession</h1>
            
//             <p className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-center max-w-xl mx-auto leading-relaxed">
//             Your Professional Journey Begins Here
//               <br />
//               With expert guidance and support, Sanjeeda promises to guide you through every step for your career advancement and professional growth
//             </p>
            
//             <div className="flex flex-col sm:flex-row sm:space-x-5 space-y-4 sm:space-y-0 justify-center">
//               <button className="primary-btn bg-TealBlue text-white w-full sm:w-auto px-4 py-3 rounded hover:bg-customBlue transition text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">
//                Let’s get started
//               </button>
//               <button className="primary-btn bg-TealBlue text-white w-full sm:w-auto px-3 py-3 rounded hover:bg-customBlue transition text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">
//                 VIEW COURSE
//               </button>
//             </div>
//           </div>
//         </div>
//       </section>

//       <div className="margin">
//         <div className="service-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-4 sm:p-8">
//           <div className="image-box bg-gray-200 shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 relative">
//             <img src="c18.jpeg" alt="" className="w-full h-auto rounded-lg" />
//           </div>
//           <div className="image-box bg-gray-200 shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 relative">
//             <img src="c9.jpeg" alt="" className="w-full h-auto rounded-lg" />
//           </div>
//           <div className="image-box bg-gray-200 shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 relative">
//             <img src="c8.jpeg" alt="" className="w-full h-auto rounded-lg" />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Hero;

// import React from 'react';
// import HAbout from "../HAbout";
// import { Link } from "react-router-dom";

// const Hero = () => {
//   return (
//     <>
//     <HAbout/>
//     <section className="hero bg-cover h-52 flex items-center justify-center">
//       <div className="container mx-auto px-6 sm:px-6 lg:px-8 md:px-2 xl:px-1">
//         <div className="row flex flex-col items-center text-center text-Blue space-y-6">
//           <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold -mt-6">Sanjeed<span className="text-Red">a!</span> Stands For:</h1>
//           <p className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-center max-w-xl mx-auto leading-relaxed text-Red font-bold">Career devlopment for people serious about </p>
//           <div className=" font-bold ">
//             <div className= "text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-center max-w-xl mx-auto leading-relaxed text-Red ">
//             <li className="relative group mr-52 -mt-1">
//             <Link to="/#readiness" className="text-black hover:underline">
//             <span className="text-Blue shadow-Red">Readiness</span> |
//             </Link>
           
//           </li>
//           <li className="relative group -mr-2 -mt-7">
//             <Link to="/#opportunities" className="text-black  hover:underline "><span className="text-Blue"> Opportunities</span> | </Link>
           
//           </li>
//           <li className="relative group -mr-52 -mt-7">
//             <Link to="/#success" className="text-black hover:underline"><span className="text-Blue">Success</span></Link>
            
//             </li>
//           </div>
//           </div>
//           </div>
//           </div>
//         </section>
//       <div className="margin h-8 bg-transparent" ></div>
//       </>
//       );
//     };

// export default Hero;

import React, { useState } from 'react';
import HAbout from "../HAbout";
import { Link } from "react-router-dom";

const Hero = () => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  return (
    <>
      <HAbout />
      <section className="hero bg-cover h-52 flex items-center justify-center bg-gradient-to-r from-[#7f81d1] to-[#0cbaf4]">
        <div className="container mx-auto px-6 sm:px-6 lg:px-8 md:px-2 xl:px-1">
          <div className="row flex flex-col items-center text-center text-Blue space-y-6">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold -mt-6">
              Sanjeed<span className="text-Red">a!</span> Stands For:
            </h1>
            <p className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-center max-w-xl mx-auto leading-relaxed text-Red font-bold">
              Career development for people serious about
            </p>
            <div className="font-bold">
              <div className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl text-center max-w-xl mx-auto leading-relaxed text-Red">
                <ul className="flex justify-center space-x-4">
                  {/* Readiness Dropdown */}
                  <li className="relative">
                    <button
                      onClick={() => toggleDropdown("readiness")}
                      className="text-Blue font-semibold focus:outline-none"
                    >
                      <span className="text-Blue hover:shadow-md hover:shadow-Red rounded-lg text-xs lg:text-xl ">
                         {/* <i class="fa fa-angle-down" aria-hidden="true"></i>  */}
                         Readiness</span>
                      <span className="text-Red"> |</span>
                    </button>
                    {openDropdown === "readiness" && (
                      <ul className="absolute mt-2 bg-white shadow-lg rounded-md p-2 space-y-1">
                        <li>
                          <Link
                            to="Registration#Registration"
                            className="block px-4 py-2 text-xs text-black"
                          >
                            Student
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="Registration#Registration"
                            className="block px-4 py-2 text-xs text-black"
                          >
                            Job Seeker
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="Registration#Registration"
                            className="block px-4 py-2 text-xs text-black"
                          >
                             Professional
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="Registration#Registration"
                            className="block px-4 py-2 text-xs text-black"
                          >
                            Mentor
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="Registration#Registration"
                            className="block px-4 py-2 text-xs text-black"
                          >
                          Employer  
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>

                  {/* Opportunities Dropdown */}
                  <li className="relative">
                    <button
                      onClick={() => toggleDropdown("opportunities")}
                      className="text-Blue font-semibold focus:outline-none "
                    >
                      <span className="text-Blue hover:shadow-md hover:shadow-Red rounded-lg text-xs lg:text-xl ">Opportunities</span>
                      <span className="text-Red"> |</span>
                    </button>
                    {openDropdown === "opportunities" && (
                      <ul className="absolute mt-2 bg-white shadow-lg rounded-md p-2 space-y-1">
                        <li>
                          <Link
                            to="/#self-discovery"
                            className="block px-4 py-2 text-xs text-black"
                          >
                            Training & development 
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/#cv-profile"
                            className="block px-4 py-2 text-xs text-black"
                          >
                            Counseling
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            to="/#career-counseling"
                            className="block px-4 py-2 text-xs text-black hover:bg-gray-100"
                          >
                            Career Counseling
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link
                            to="/#job-support"
                            className="block px-4 py-2 text-xs text-black hover:bg-gray-100"
                          >
                            Job Support Services
                          </Link>
                        </li> */}
                      </ul>
                    )}
                  </li>

                  {/* Success Dropdown */}
                  <li className="relative">
                    <button
                      onClick={() => toggleDropdown("success")}
                      className="text-Blue font-semibold  focus:outline-none"
                    >
                      <span className="text-Blue hover:shadow-md hover:shadow-Red rounded-lg text-xs lg:text-xl">Success</span>
                      
                    </button>
                    {openDropdown === "success" && (
                      <ul className="absolute mt-2 bg-white shadow-lg rounded-md p-2 space-y-1">
                        <li>
                          <Link
                            to="/#self-discovery"
                            className="block px-4 py-2 text-xs text-black"
                          >
                          Success Stories  
                          </Link>
                        </li>
                        {/* <li>
                          <Link
                            to="/#cv-profile"
                            className="block px-4 py-2 text-xs text-black hover:bg-gray-100"
                          >
                            CV & Profile Services
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link
                            to="/#career-counseling"
                            className="block px-4 py-2 text-xs text-black hover:bg-gray-100"
                          >
                            Career Counseling
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link
                            to="/#job-support"
                            className="block px-4 py-2 text-xs text-black hover:bg-gray-100"
                          >
                            Job Support Services
                          </Link>
                        </li> */}
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="margin h-8 bg-transparent"></div>
    </>
  );
};

export default Hero;
