import React, { useState } from "react";
import { faq } from "../../dummydata";


const Faq = () => {
  const [click, setClick] = useState(null);

  const toggle = (index) => {
    if (click === index) {
      return setClick(null);
    }
    setClick(index);
  };

  return (
    <>
      <h2 className="text-lg font-bold text-center text-Blue">FAQS</h2>
      <h1 className="text-4xl font-semibold text-center text-Red">Frequently Asked Questions</h1>
      <section className="faq text-Blue py-16"
       
        >
        <div className="container mx-auto px-40 ">
          {faq.map((val, index) => (
            <div
              className="mb-4 border-b border-Red transition-shadow duration-300 "
              key={index}
            >
              <button
                className="w-full text-left py-4 px-6 flex justify-between items-center bg-white shadow-darkBlue shadow-lg rounded-lg"
                onClick={() => toggle(index)}
              >
                <h2 className="text-lg font-semibold">{val.title}</h2>
                <span>
                  {click === index ? (
                    <i className="fa fa-chevron-down text-Red"></i>
                  ) : (
                    <i className="fa fa-chevron-right text-Blue"></i>
                  )}
                </span>
              </button>
              {click === index && (
                <div className="text p-6 bg-white rounded-lg mt-2 shadow-darkBlue shadow-lg">
                  <p className="text-Red">{val.desc}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Faq;
