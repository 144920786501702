// export const homeAbout = [
//     {
//       id: 1,
//       cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
//       title: "Career Counseling",
//       desc: "Here to help you with personalized guidance, making it easier to navigate your professional journey and reach success.",
//     },
//     {
//       id: 1,
//       cover: "https://img.icons8.com/ios/80/000000/diploma.png",
//       title: "Offer Acceptance Calculator",
//       desc: "Empowering individuals to evaluate job offers effectively to make informed decisions about their career choices.",
//     },
//     {
//       id: 1,
//       cover: "https://img.icons8.com/ios/80/000000/athlete.png",
//       title: "Job Search",
//       desc: "Offering job search support to help you find the right opportunity and secure your ideal position.",
//     },
//   ]
  export const awrapper = [
    {
      cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
      data: "3,000",
      title: "SUCCESS STORIES",
    },
  
    {
      cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
      data: "320",
      title: "TRUSTED TUTORS",
    },
    {
      cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
      data: "1,000",
      title: "SCHEDULES",
    },
    {
      cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
      data: "587",
      title: "COURSES",
    },
  ]
  
  export const servicesCard = [
    {
      id: 1,
      cover: "/courses/img.png", // Example path for service image
      ServicesName: "Database Management and Optimization",
      desc: "8.For enhanced data efficiency for recruiters and employers.",
    },
    {
      id: 2,
      cover: "/courses/c1.png",
      ServicesName: "Your Perfect 3D CV",
      desc: "1.Easily create a standout CV tailored to your unique skills and experiences.",
    },
    {
      id: 3,
      cover: "/courses/c2.png",
      ServicesName: "Ideal Job Matches",
      desc: " Variety of desirable job openings, making it easy to apply and find opportunities that fit your skills.",
    },
    {
      id: 4,
      cover: "/courses/c3.jpg",
      ServicesName: "Interview Preparation",

      desc: "2. Personalized mock interview services to boost your interview success.",
    },
    {
      id: 5,
      cover: "/courses/c4.png",
      ServicesName: "Skill Development Training",
      desc: "Designed for job seekers to improve and polish your skills to elevate your career.",
    },
    {
      id: 6,
      cover: "/courses/c5.png",
      ServicesName: "Offer Acceptance Calculator",
      desc: "Evaluate and compare multiple job offers based on key factors like salary, benefits, and growth opportunities.",
    },
    {
      id: 7,
      cover: "/courses/c6.jpg",
      ServicesName: "Career Focused Programs",
      desc: "Training and mentorship to help individuals gain the skills and confidence needed",
    },
    {
      id: 8,
      cover: "/courses/s9.jpg",
      ServicesName: "Career Assessments",
      desc: " Tools to evaluate your skills and personality to identify suitable career paths",
    },
    {
      id: 9,
      cover: "/courses/s9.png",
      ServicesName: "Expert Consultancy Services",
      desc: "Personalized strategies and insights to help you navigate challenges, enhance performance, and achieve your goals.",
    },
  ];
  
  export const team = [
    // {
    //   cover: "./images/team/t1.webp",
    //   name: "Ph.D Adrian Molises",
    //   work: "DEVELOPER AND LEAD INSTRUCTOR",
    // },
    // {
    //   cover: "./images/team/t2.webp",
    //   name: "Ph.D Arthur MaGregor",
    //   work: "DEVELOPER AND LEAD INSTRUCTOR",
    // },
    // {
    //   cover: "./images/team/t3.webp",
    //   name: "Ph.D Anna Hanzen",
    //   work: "DEVELOPER AND LEAD INSTRUCTOR",
    // },
    // {
    //   cover: "./images/team/t4.webp",
    //   name: "Ph.D Brian Wooden",
    //   work: "DEVELOPER AND LEAD INSTRUCTOR",
    // },
    {
      cover: "./images/team/t7.jpg",
      name: "Anees Ahmed (Executive Director)",
      work: "Lead Big Data & AI , automation Engineer Machine Learning | Deep Learning | Artificial Intelligence | Manager Data & AI Practices - RPA | EX GfK - An NIQ Company | Lead AI Trainer, PIAIC |",
    },
    // {
    //   cover: "./images/team/t5.webp",
    //   name: "Ph.D Adrian Molises",
    //   work: "DEVELOPER AND LEAD INSTRUCTOR",
    // },
    {
      cover: "./images/team/mk.jpg",
      name: "Muhammad Majid Khan(Executive Director)",
      work: "DEVELOPER AND LEAD INSTRUCTOR",
    },
    // {
    //   cover: "./images/team/t7.jpg",
    //   name: "Anees Ahmed (Executive Director)",
    //   work: "Lead Big Data & AI , automation Engineer Machine Learning | Deep Learning | Artificial Intelligence | Manager Data & AI Practices - RPA | EX GfK - An NIQ Company | Lead AI Trainer, PIAIC |",
    // },
    {
      cover: "./images/team/fa.jpg",
      name: "Mirza Faizan Baig (CEO)",
      work: "Career Consultant / HR Consultant Faizan is the founder of Conductivity Career & HR Solutions. with 17 years of experience in HR and passion for Career Development.",
    },
  ]
  export const price = [
    {
      name: "BASIC PLAN",
      price: "49K",
      desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    },
    {
      name: "BEGINNER PLAN",
      price: "79K",
      desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    },
    {
      name: "PREMIUM PLAN",
      price: "109k",
      desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    },
    {
      name: "ULTIMATE PLAN",
      price: "149K",
      desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    },
  ]
  export const faq = [
    {
      title: "What is Sanjeeda.org?",
      desc: "Sanjeeda.org is a platform dedicated to empowering job seekers and professionals by providing career-focused resources. We offer personalized services for students, jobseekers, recruiters and employers. These tools are designed to help individuals enhance their careers, optimize job search strategies, and successfully navigate the evolving job market.",
    },
    {
      title: "What kind of content can I find on Sanjeeda.org?",
      desc: "Sanjeeda provides articles, blogs, tutorials, videos, and interactive tools on topics like CV building, career development strategies, job search techniques, interview preparation and personal branding. ",
    },
    {
      title: "Can I contribute content to Sanjeeda.org?",
      desc: "Absolutely! We welcome contributions from experts and enthusiasts alike. If you're interested in writing for us or sharing your expertise, please visit our Contribute page for more details on submission guidelines.",
    },
    {
      title: "Does Sanjeeda.org have any social media presence?",
      desc: "Yes! You can find us on LinkedIn, Facebook, Instagram, Twitter and YouTube. Follow us for updates, community discussions, and more.",
    },
    {
      title: "5.What makes Sanjeeda.org unique?",
      desc: "Unlike other sites, we focus on offering personalized, in depth guides and tailored career strategies catered to address individual needs. Plus, we’re constantly listening to user feedback, ensuring that our content delivers the most relevant and useful resources to help you succeed in your career.",
    },
    {
      title: "How It is hidden by default, until the collapse?",
      desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
  ]
  export const blog = [
    {
      id: 1,
      type: "admin",
      date: "JAN. 18, 2021",
      com: "3 COMMENTS ",
      title: "Workplace culture",
      desc: "Workplace culture is the shared values, beliefs, and practices that shape how employees interact, collaborate, and approach their work. A positive culture enhances employee satisfaction and engagement, fostering a space where people feel valued, motivated, and connected to the organization’s goals. By emphasizing clear values, open communication, and supportive leadership, organizations create an environment where trust and teamwork thrive, ultimately strengthening the overall work dynamic. Beyond the interpersonal aspects, the physical environment and recognition of achievements also play crucial roles in shaping culture. A well-designed, comfortable workspace, combined with a culture of acknowledgment, boosts morale and reinforces loyalty. When employees feel both supported and celebrated for their contributions, it elevates job satisfaction, productivity, and retention—helping both individuals and the organization reach their full potential.",
      cover: "../images/blog/b1.webp",
    },
    {
      id: 2,
      type: "admin",
      date: "API. 25, 2022",
      com: "5 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b2.webp",
    },
    {
      id: 3,
      type: "user",
      date: "MAY. 15, 2022",
      com: "10 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b3.webp",
    },
    {
      id: 4,
      type: "admin",
      date: "JAN. 02, 2022",
      com: "3 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b4.webp",
    },
    {
      id: 5,
      type: "admin",
      date: "DEC. 14, 2022",
      com: "3 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b5.webp",
    },
    {
      id: 6,
      type: "user",
      date: "JAN. 18, 2021",
      com: "12 COMMENTS ",
      title: "Build your Dream Software & Engineering Career",
      desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
      cover: "../images/blog/b6.webp",
    },
  ]
  export const testimonal = [
    {
      id: 1,
      name: "ROGER SCOTT",
      post: "MARKETING MANAGER",
      desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
      cover: "./images/testo/t1.webp",
    },
    {
      id: 2,
      name: "ROGER SCOTT",
      post: "MARKETING MANAGER",
      desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
      cover: "./images/testo/t2.webp",
    },
    {
      id: 3,
      name: "ROGER SCOTT",
      post: "MARKETING MANAGER",
      desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
      cover: "./images/testo/t3.webp",
    },
  ]
  export const CVs = [{
    header: {
      name: "Saad Ahsan (PMP)",
      contact: "+92332-368-2212",
      email: "xaadahsan1@gmail.com",
      address: "Flat # D9/6 Afnan Duplex Houses Gulistan-e-Johar",
      tags: ["PMP Certified | Experienced Professional - E-commerce platforms | Startups | X-foodpanda | SalesForce Digital marketing | Web analytics tools | SQL | Jira | Trello | Asana | UI/UX design | Wireframing | SEO toolsAction oriented | Dynamic | Team Player | Master’s in Project Management | Service Design Expertise"]
    },
    profile: [
      "Expert at enhancing search and findability, optimizing delivery fees, and automating tasks",
      "Qualified Project Management Professional: PMP from PMI and Master’s in Project Management",
      "Proven track record in Project Management, data analysis, E-commerce & salesforce management"
    ],
    careerSummary: [
      {
        "organization": "Siar Digital",
        "position": "Assistant Project Manager",
        "from": "June 2023",
        "to": "To Date"
      },
      {
        "organization": "FoodPanda",
        "position": "Content Associate",
        "from": "April 2019",
        "to": "June 2023"
      },
      {
        "organization": "Apexchat (Now Breakthru)",
        "position": "Territory Manager",
        "from": "March 2017",
        "to": "April 2019"
      },
      {
        "organization": "Pinnacle Enterprises",
        "position": "Customer Service Representative",
        "from": "Dec 2015",
        "to": "Feb 2017"
      }
    ],
    qualifications: {
      "title": "Professional & Academic Qualification",
      "details": [
        {
          "qualification": "MS Project Management",
          "institution": "Bahria University, Karachi",
          "gpa": "2.94",
          "yearOfCompletion": 2024
        },
        {
          "qualification": "BS Geo-physics",
          "institution": "Bahria University, Karachi",
          "gpa": "2.88",
          "yearOfCompletion": 2019
        }
      ]
    },
    trainingDevelopment: [
      {
        "certification": "PMP®",
        "institute": "PMI",
        "year": "2024"
      },
      {
        "certification": "NEBOSH",
        "institute": "SGS Pakistan",
        "year": "2020"
      },
      {
        "certification": "Introductory GIS",
        "institute": "Institute of Skill Development pakistan",
        "year": "2017"
      }
    ],
    projects: [
      {
        project: "Image Pakistan | Alupak | Zainab Chottani | Tahra | Salitex | Dreams | Ngents | Chyll | Noorma Kamal | Wardha Saleem | ZC International | Little Pineapple | Lamour | TMI | Sumaira Khanani | Online Bazaa | Umsha | Amna Arshad | Neeks Closet | Top Stone | Sunday Linen Usha | Zuri by Zainab | Asra Jofa | Nainpreet | Nomi Ansari",
        details: [
          "Strategic Project Planning and Execution",
          "Payment Methods and Legal Compliance",
          "ERP System Implementation",
          "CRM Integration for Customer Support",
          "Enhanced Brand Identity and Customer Experience",
          "Advanced Digital Marketing Integration",
          "Technical Proficiency and Domain Management",
          "Streamlined Data Migration and Quality Assurance",
          "Cross-functional Collaboration"
        ],
        sponsor: "Siar Digital"
      },
      {
        project: "Lal’ Delivery App | Asim Jofa Mobile App | Search and Findability Enhancement | Optimization of Delivery Fee and Time-based Pricing | Automation and Task Reduction | Menu Updates and Creation using foodpanda Backend Portal",
        details: [
          "Enhanced search functionality and algorithms",
          "Aligned customer demands with services",
          "Improved search and findability features",
          "Optimized delivery fee and time-based pricing",
          "Automated manual tasks and processes",
          "Enhanced UI/UX for better customer experience",
          "Updated and created menus in the foodpanda portal",
          "Managed deals and promotions in the backend portal",
          "Conducted menu audits for accuracy and consistency",
          "Collaborated with vendors for menu updates"
        ],
        sponsor: "foodpanda"
      },
      {
        project: "Mississauga Dolphins social media campaign",
        details: [],
        sponsor: "Personal Projects"
      }
    ],
    
    competence: [
      {
        skills: "Data Analysis",
        abilities: "Diversity management",
        knowledge: "Project Management"
      },
      {
        skills: "Salesforce",
        abilities: "Conflict management",
        knowledge: "E-commerce Platforms"
      },
      {
        skills: "Creative Designing",
        abilities: "Simplifying complexities",
        knowledge: "Digital Marketing"
      },
      {
        skills: "WordPress websites",
        abilities: "Strategic thinking",
        knowledge: "Salesforce Management"
      },
      {
        skills: "Critical thinking",
        abilities: "Logical & empirical",
        knowledge: "Data Analysis"
      },
      {
        skills: "Project Management",
        abilities: "Stakeholder management",
        knowledge:  "UI/UX Design"
      },
      {
        skills: "Team management",
        abilities: "Self-driven",
        knowledge: "Agile Tools (Jira, Asana, Trello)"
      }
    ],
    awardsAchievements: [
      "Winner of numerous chess competitions",
      "Panda of the Quarter",
      "Panda of the Month",
      "Calmer of Storms",
      "The Braniac"
    ],}
    
  ]
    