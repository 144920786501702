
import React from 'react';
import { useParams } from 'react-router-dom';
// import './BlogPage.css';
const TestimonalPg = () => {
  const { id } = useParams();
  const TestimonalPgData = {
    1: { title: 'Syed Muhammad Asad' },
    2: { title: ' Zeeshan’s Story'},
    3: { title: 'Syed Saqib Hassain' },
  };
  const Testimonal = TestimonalPgData[id];

  return (
    <div className="Testimonal-pg px-4 py-6 bg-white">
      {id === "1" && (

      <div className="interview-struggles bg-white shadow-lg rounded-lg p-6 md:p-8">
      <h1 className='centered text-2xl font-bold text-Red text-center mb-6'>{Testimonal.title}</h1>
      <blockquote className='text-black space-y-4'>
      <p className='text-lg pl-4'>Hi, I’m Asad, a social media marketing manager at Conductivity and a passionate sports enthusiast. My journey has been shaped by two driving forces: my love for sports and the invaluable mentorship of Mirza Faizan Baig (CEO Conductivity). <br /></p> 
      <p className='text-base pl-6'>
      In 2018, Mirza Faizan Baig became my mentor, guiding me during a pivotal moment in my life. At the time, I aspired to become a sports shooter but faced limited opportunities in Pakistan. He analyzed my strengths and suggested to explore archery as a more feasible alternative. With his encouragement, I dove into archery, dedicating four years to mastering the sport. This effort led me to represent Karachi University, Pakistan Customs, and Sindh on a national level. Today, I am proud to be a national player, a national level archery coach, and the founder of my own brand “Roar Archery”.
      </p>
      <p className='text-base pl-6'>
      However, just the thought of giving an interview can be extremely daunting and stressful for many. In today’s competitive job market, 
      being well-prepared and confident is very important. This article explores some key points to help you prepare for a job interview, 
      common challenges you might face or mistakes you might make and how to handle those situations with confidence."
      </p>
      <ol className='list-decimal list-inside space-y-4 mt-4'>
      <p className='text-base pl-6'>
      In 2022, on Mirza Faizan Baig’s recommendation, I joined Conductivity. Starting in administration, I later transitioned to social media marketing, where my prior experience in content editing proved invaluable. Over time, I groomed my skills and now manage social media campaigns for Conductivity and other organizations as well.
      </p>
      <p className='text-base pl-6'>
      My journey is one of continuous growth, fueled by mentorship, determination, and the willingness to adapt. While I have achieved several milestones, I remain focused on pursuing new goals.
      </p>
      </ol>
    </blockquote>
     
    </div>
      )}
{id === "2" && (

<div className="interview-struggles bg-white py-8 px-6 lg:px-11 rounded-lg shadow-md">
 <h1 className="centered font-bold text-3xl text-Red text-center mb-6">{Testimonal.title}</h1>
 <blockquote className="space-y-4 text-black leading-relaxed">
 <p className="text-base pl-6">Hi, I’m Zeeshan Hirani. I am not directly employed at Conductivity, but I had the privilege of working closely with Mirza Faizan Baig during my tenure at Deloitte, which began in 2014, where I served as an HR Coordinator under the supervision of Mirza Faizan Baig, who recognized my potential and consistently guided me to focus on critical HR domains, including Employee Relations, Secondments, Change Management, and other key operational HR areas. I transitioned into central HR roles at Deloitte Yousuf Adil, allowing me to gain hands-on experience in Recruitment and the pre-implementation phase of HRIS systems.<br /></p> 
 <p className='text-base pl-6'>
 In 2023, Mirza Faizan Baig demonstrated immense confidence in my abilities, entrusting me to lead the HR function at Technology Links (Pvt) Ltd. This opportunity was transformative, as it enabled me to develop leadership skills while gaining comprehensive exposure to essential HR domains such as Payroll Management, Performance Appraisal, and Compensation & Benefits.
 </p>
 
 
 <p className='text-base pl-6'>
 The journey of mentorship has played a pivotal role in my life, with Faizan’s guidance shaping my professional path. His ability to support, empower, and inspire has left a lasting impact on both my career and personal growth. I am deeply grateful for his belief in my potential and his dedication to nurturing talent
 </p>
</blockquote>
 
</div> 
)} {id === "3" && (

<div className="interview-struggles bg-white py-8 px-6 lg:px-11 rounded-lg shadow-md">
 <h1 className="centered font-bold text-3xl text-Red text-center mb-6">{Testimonal.title}</h1>
 <blockquote className="space-y-4 text-black leading-relaxed"> 
 <p className="font-medium">Recruitment Strategies: How to win the talent war by using strategies that can attract top candidates<br /></p> 
 <p className='text-base pl-6'>
 In today’s competitive job market, snagging the best talent requires thinking outside the box with innovative and strategic approaches. Gone are the days of simply posting a job ad and hoping for the best. To really shine and attract those top candidates, companies need to get creative. This means not just showcasing your company’s perks, but also understanding what candidates truly want. With the right approach, you can set your organization apart and make it a magnet for the talent you are looking for. Here are some recruitment strategies that can help your company stand out and attract the best candidates:
 </p>
 
 <h2 className="text-xl font-semibold text-black mt-6"> Boost your Employer Brand</h2>
 <ol className="list-decimal list-inside space-y-4">
 
 <p className='text-base pl-6'>
 A strong employer brand shows what your company stands for, its culture, mission, vision and its goals. Share your workplace and its unique characteristics through social media, posts, stories, videos, blogs and other platforms to boost its good image and success. It’s important to highlight what makes your organization special, like benefits for employees, the workplace culture, chances for career growth, and the overall work environment.
 </p>

 <li><h4 className="font-bold mt-2">Utilize Social Media </h4></li>
 <p className="text-base pl-6">
 Utilize social media platforms to connect with potential candidates in the spaces where they are active the most like LinkedIn, Facebook etc. Regularly share job postings, company updates, and engaging content that highlights your workplace culture. This could include funny or relatable content like behind-the-scenes, team or training events, and employee achievements. Also encourage your employees to share their experiences and promote job openings within their own networks. By actively engaging with your followers / connections, you create an online presence that draws in top talent.
 </p>

<li><h4 className="font-bold mt-2">Capitalize on Employee Referrals</h4></li>
 <p className="text-base pl-6">
 Encouraging your current employees to refer candidates for open positions can greatly boost your recruitment efforts. Referrals often lead to better cultural fits, as employees tend to suggest individuals who align with your company’s values. Additionally, this approach can reduce the time and cost associated with hiring.
 </p>
</ol>

 <h2 className="font-bold mt-2"> Improve Job Descriptions</h2>
 <ol>
 {/* <li><h4 className="font-bold mt-2">Higher Salaries and Career Growth:</h4></li> */}
 <p className="text-base pl-6">
 It’s vital that you post a clear and engaging job description that accurately reflects the role and its responsibilities. Focus on the skills and qualifications that are actually required and matter the most. Highlight opportunities for growth and development to attract ambitious candidates
 </p>

 <li><h4 className="font-bold mt-2">Multicultural experience:</h4></li>
 <p className="text-base pl-6">
 Living and working in another country opens your eyes to new cultures, ideas, and work environments, giving you a fresh perspective on life and work. You also get to experience the way people interact with one another and might help you to pick up a language or two on the way. It can also help you to build a professional network that is global and it can bring about more future opportunities and collaborations. 
 </p>
 
 <li><h4 className="font-bold mt-2">Prioritise Candidate’s Experience</h4></li>
 <p className="text-base pl-6">
 Make sure to provide a positive candidate experience throughout the recruitment process. Clearly communicate all the instructions, job responsibilities and requirements, provide proper feedback, and keep candidates updated throughout the whole process. This will demonstrate the company’s positive culture and how much they value the candidates even if they don’t get chosen and will leave a long lasting impression.
 </p>
 
 <li><h4 className="font-bold mt-2"> Use Data-Driven Recruitment</h4></li> 
 <p className="text-base pl-6">
 Use data analytics to guide your hiring strategy. Look at the numbers like how many people are applying, the quality of candidates, and how long it takes to hire someone. By examining these details, you can find ways to improve your recruitment process. Insights from this data help you make better decisions and improve your hiring methods.
 </p>
 <li><h4 className="font-bold mt-2">Connect with passive candidates</h4></li> 
 <p className="text-base pl-6">
 Many top candidates might not be actively seeking new jobs. Use platforms like LinkedIn to connect with these candidates by highlighting what makes your company unique and attractive. As mentioned before, share interesting and engaging content about your workplace. When reaching out, personalize your messages to make a genuine long lasting connection, letting them know that you value their skills and experiences and will be there for them in the future just in case. 
 </p>
 <li><h4 className="font-bold mt-2">Flexible work options</h4></li> 
 <p className="text-base pl-6">
 Many candidates nowadays look for flexible work arrangements, such as remote work or flexible hours, so it’s important that you introduce that option to attract variety of talents. A lot of candidates value a proper work life balance, so highlighting your organization’s flexible options can provide you with a distinct advantage and give you a competitive edge.
 </p>
 <li><h4 className="font-bold mt-2">Participate in Job/ Career fairs and Social events</h4></li> 
 <p className="text-base pl-6">
 To increase your company’s visibility, engage a team that prepares for and attends job / career fairs and other networking events where they can interact with and interview potential candidates. These events allow you to connect with job seekers face-to-face, build relationships and connections, and promote your organization / employer brand.
 </p>
 <li><h4 className="font-bold mt-2"> Invest in Training and Development</h4></li> 
 <p className="text-base pl-6">
 Highlight your organization’s dedication to employee growth through training and development programs. Candidates are often attracted to companies that invest in their employees' skills and career advancement.
Need more tips and advice on how to boost your company? Contact Conductivity Consultancy (linkedin page) and work with us to hire the right people and grow your organisation. Leave a comment if you have any queries!
 </p>
 </ol>

</blockquote>
 
</div> 
)}      
     </div>
  );
};

export default TestimonalPg;

 
