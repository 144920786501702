import React from 'react'
import Header1 from './Header1'
// import Header1 from '....../Header1.js'

const CVs = () => {
  return (
    <>
      {/* <Header1/> */}
      <Header1/>
    </>
    
  )
}

export default CVs