import React from "react"


const Contact = () => {
  const map = 'https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d904726.6131739549!2d85.24565535!3d27.65273865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1652535615693!5m2!1sen!2snp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"'

  return (
    <>
      
      <section className='contacts py-16 bg-cover bg-center bg-[#dcdce8] '>
          
        <div className='container mx-auto p-8 bg-[#5263b9] shadow-lg flex flex-col lg:flex-row bg-gradient-to-r from-[#484bf1] to-[#71311a]'>
          <div className='left w-full lg:w-1/2 mb-8 lg:mb-0 lg:mr-8'>
          <iframe 
          src={map}
          title="Google Maps Location"
          className="w-full h-96 border-0"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"></iframe>

          </div>
          <div className='right w-full lg:w-1/2'>
            <h1 className='text-xs lg:text-lg font-bold mb-4 text-white
            '>Contact us</h1>
            <p className='mb-8 text-white text-xs lg:text-sm '>We're open for any suggestion or just to have a chat</p>

            <div className='items grid grid-cols-1 md:grid-cols-2 gap-8 mb-8'>
              <div className='box'>
                <h4 className='text-xs lg:text-sm font-semibold text-white'>ADDRESS:</h4>
                <p  className='text-xs lg:text-sm text-white'>Conductivity Careers & HR Soluions B65, Block 2 Gulshan-e-Iqbal, Karachi</p>
              </div>
              <div className='box'>
                <h4 className='text-xs lg:text-sm font-semibold text-white'>EMAIL:</h4>
                <p className="text-white text-xs lg:text-sm"> info@conductivity.com.pk</p>
              </div>
              <div className='box'>
                <h4 className='text-xs lg:text-sm font-semibold text-white'>PHONE:</h4>
                <p className="text-white text-xs lg:text-sm">03132193503 , +92 21 34832777</p>
              </div>
            </div>

            <form action='' className='mb-8'>
              <div className='flex flex-col md:flex-row gap-2 mb-2 '>
                <input
                  type='text'
                  placeholder='Name'
                  className='w-80 h-10 p-4 border border-gray-300 rounded'
                />
                <input
                  type='email'
                  placeholder='Email'
                  className='w-80 h-10 p-4 border border-gray-300 rounded'
                />
              </div>
              <input
                type='text'
                placeholder='Subject'
                className='w-80 h-10 p-4 mb-4 border border-gray-300 rounded'
              />
              <textarea
                cols='30'
                rows='10'
                placeholder='Create a message here...'
                className='w-80 h-20 p-4 mb-4 border border-gray-300 rounded'
              ></textarea>
              <button className='primary-btn bg-Red text-white px-8 py-3 rounded hover:bg-Blue
              transition text-xs lg:text-sm h-10'>
                SEND MESSAGE
              </button>
            </form>

            <h3 className=' text-white text-xs lg:text-sm font-semibold mb-4'>Follow us here</h3>
            {/* <span className='text-white text-xs lg:text-sm'>FACEBOOK TWITTER INSTAGRAM DRIBBBLE</span> */}
            <div className="social flex space-x-4 text-white ">
          <ul className="flex justify-center items-center space-x-6 ">
            <li>
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our Facebook page"
                className="text-black hover:text-Blue transition duration-300 "
              >
                <i className="fab fa-facebook-f text-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our Instagram page"
                className="text-black hover:text-pink transition duration-300"
              >
                <i className="fab fa-instagram text-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our YouTube page"
                className="text-black hover:text-Red transition duration-300"
              >
                <i className="fab fa-youtube text-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our Twitter page"
                className="text-black hover:text-Blue transition duration-300"
              >
                <i className="fab fa-twitter text-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our LinkedIn page"
                className="text-black hover:text-Blue transition duration-300"
              >
                <i className="fab fa-linkedin-in text-xl"></i>
              </a>
            </li>
          </ul>
        </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
