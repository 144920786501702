import React from 'react';

const Head = () => {
  return (
    <section
      className="py-6  bg-[#dcdce8]">
      <div className="container max-w-screen-xl mx-auto flex flex-col sm:flex-row justify-between items-center px-4">
        {/* Logo Section */}
        <div className="logo text-3xl font-bold mb-6 sm:mb-0 ">
          <h1 className="flex items-baseline space-x-1">
            <span className="text-Red">Sanjeed</span>
            <span className="text-[#081b9c]">a!</span>
          </h1>
        </div>

        {/* Social Icons Section */}
        <div className="social flex space-x-4">
          <ul className="flex justify-center items-center space-x-6">
            <li>
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our Facebook page"
                className="text-black hover:text-Blue transition duration-300"
              >
                <i className="fab fa-facebook-f text-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our Instagram page"
                className="text-black hover:text-pink transition duration-300"
              >
                <i className="fab fa-instagram text-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our YouTube page"
                className="text-black hover:text-Red transition duration-300"
              >
                <i className="fab fa-youtube text-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our Twitter page"
                className="text-black hover:text-Blue transition duration-300"
              >
                <i className="fab fa-twitter text-xl"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit our LinkedIn page"
                className="text-black hover:text-Blue transition duration-300"
              >
                <i className="fab fa-linkedin-in text-xl"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
     </section>
  );
};

export default Head;
