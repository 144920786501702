import React, { useState } from "react";

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState(""); // For managing input value
  const [isOpen, setIsOpen] = useState(false); // For toggling the search bar

  const handleSearch = () => {
    // Perform your search action here
    console.log("Searching for:", searchQuery);
  };

  return (
    <div className="relative">
      {/* Open Search Button */}
      {!isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          // className="lg:hidden items-center px-2 py-2 text-white rounded-md relative -right-60 mt-1 -mr-16"
          //  className="absolute -mr-16 lg:hidden right-14 text-white px-1 py-3 xs:px-4 xs:py-3 xs:right-3 -mt-6"
          className="absolute -mr-20 right-24 -mt-6 text-white px-14 py-3 xs:px-4 xs:py-3 xs:right-10 lg:hidden"
        >
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>
      )}

      {/* Search Bar */}
      {isOpen && (
        <div className="lg:hidden flex items-center px-10 space-x-2">
          <input
            type="text"
            className="px-4 py-2 rounded-md text-sm text-black focus:outline-none absolute right-24 "
            placeholder="Search here..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button
            onClick={handleSearch}
            className="px-2 py-2 absolute right-24 rounded-sm"
          >
             <i class="fa fa-search" aria-hidden="true"></i>
          </button>
          <button
            onClick={() => setIsOpen(false)}
            className="px-3 py-2  text-white rounded-md absolute right-14"
          >
            ✖
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
// import React, { useState } from "react";

// const SearchBar = ({ handleSearch }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <div className="relative">
//       {/* Open Search Button */}
//       {!isOpen && (
//         <button
//           onClick={() => setIsOpen(true)}
//           className="lg:hidden items-center px-2 py-2 bg-blue-600 text-white rounded-md relative -right-64 mt-1 -mr-16"
//         >
//           <i className="fa fa-search" aria-hidden="true"></i>
//         </button>
//       )}

//       {/* Search Bar */}
//       {isOpen && (
//         <div className="lg:hidden flex items-center px-10 space-x-2">
//           <input
//             type="text"
//             className="px-4 py-2 rounded-md text-sm bg-blue-700 text-black focus:outline-none relative right-14"
//             placeholder="Search here..."
//             onChange={handleSearch}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default SearchBar;

// import React, { useState } from "react";

// const SearchBar = () => {
//   const [searchQuery, setSearchQuery] = useState(""); // For managing input value
//   const [isOpen, setIsOpen] = useState(false); // For toggling the search bar

//   const handleSearch = () => {
//     // Perform your search action here
//     console.log("Searching for:", searchQuery);
//   };

//   return (
//     <div className="relative">
//       {/* Open Search Button */}
//       {!isOpen && (
//         <button
//           onClick={() => setIsOpen(true)}
//           className="lg:hidden items-center px-2 py-2 text-white rounded-md relative -right-60 mt-1 -mr-16Z"
//         >
//           <i className="fa fa-search" aria-hidden="true"></i>
//         </button>
//       )}

//       {/* Search Bar */}
//       {isOpen && (
//         <div className="lg:hidden flex items-center px-10 space-x-2">
//           <input
//             type="text"
//             className="px-4 py-2 rounded-md text-sm text-black focus:outline-none relative right-14"
//             placeholder="Search here..."
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//           />
//           <button
//             onClick={handleSearch}
//             className="px-4 py-2 bg-blue-600 text-white rounded-md"
//           >
//             Search
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SearchBar;